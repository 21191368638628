import React, { useState, useEffect } from 'react';
import { useSitesContext } from '../../hooks/useSitesContext';
import { useAuthContext } from '../../hooks/useAuthContext';



const SiteGatewaySelect = ({ site}) => {
    const { dispatch } = useSitesContext();
  const { user } = useAuthContext()
  const [error, setError] = useState(null)
  
  const [devices, setDevices] = useState(null);
  const [listOfGateways,setListOfGateways] =  useState([]);

  const closeForm = () => {
    dispatch({ type: 'SET_FORM_FLAG', payload: null });
    dispatch({ type: 'SET_FORMOPEN_FLAG', payload: null });
  };
  
  useEffect(()=>{
    const fetchGateways = async () => {
      const rootUrl = window.location.origin;
      const response = await fetch(`${rootUrl}/api/gateways`, {
        headers: {'Authorization': `Bearer ${user.token}`},
      })
      const json = await response.json()
      if (response.ok) {
        setListOfGateways(json)   
      }
    }
    if(user){
      fetchGateways();
    }
  },[])

  const addDevice = () => {
    let holdDeviceArray = [...devices]
    holdDeviceArray.push({gatewayID: listOfGateways[0]["gatewayID"], gatewayID_id: ''})
    setDevices(holdDeviceArray)
  };

  const deleteDevice = (index) => {
    let holdDeviceArray = [...devices]
    holdDeviceArray[index] = null
    setDevices(holdDeviceArray)
  };

  const handleSubmit = async (e) => {
    const devicesLen = devices.length
    let holdDeviceArray = []
    let holdDeviceArray_index = 0
    for(let i =0;i<devicesLen;i++){
      if(devices[i]!==null){
        holdDeviceArray[holdDeviceArray_index++] = devices[i]
      }
    }
    if(holdDeviceArray.length>0){
      if (!user) {
        setError('You must be logged in')
        return
      }

      const rootUrl = window.location.origin; // Get the root URL dynamically
      const holdObj = {siteID: site._id,array: holdDeviceArray}

      try {
        const response = await fetch(`${rootUrl}/api/sites/gateway`, {
          method: 'POST',
          body: JSON.stringify(holdObj),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}`
          }
        });
    
        if (!response.ok) {
          const errorMessage = `Server error: ${response.status} - ${response.statusText}`;
          console.error(errorMessage);
          setError(errorMessage);
          return;
        }
        // If the response is successful, you can extract and display the response message
        const responseData = await response.json();
        alert(responseData.message)
        closeForm()
      } catch (error) {
        console.error('An error occurred:', error);
        setError('An error occurred while communicating with the server');
      }      
    }else{alert("Table is empty, data not saved")}
  };

  const getDataFromServer = async ()=>{

    const rootUrl = window.location.origin; 
    try {
      const fetchDataJSON = {site_id: site._id}
      const response = await fetch(`${rootUrl}/api/sites/gateway/${JSON.stringify(fetchDataJSON)}`, {
        method: 'GET',       
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      });
  
      if (!response.ok) {
        const errorMessage = `Server error: ${response.status} - ${response.statusText}`;
        console.error(errorMessage);
        setError(errorMessage);
        return;
      }

      const holdJson = await response.json();
      setDevices(holdJson)
      return holdJson;
    } catch (error) {
      console.error('An error occurred:', error);
      setError('An error occurred while communicating with the server');
    }
  };

  useEffect(()=>{
    getDataFromServer();
  },[])

  
  const handleOnChange_gateway = (e,deviceIndex)=>{
    let holdDeviceArray = [...devices]
    holdDeviceArray[deviceIndex]["gatewayID"] = e.target.value
    setDevices(holdDeviceArray)
  }
  
  return (
    <>
    <div id="formScrollContainer">
        {(devices!==null)&&devices.map((device, gatewayIndex) => ((device!==null)?(
        <div key={gatewayIndex} className="device">
            <div className="parametersContainer">
            <table className="parameterTable">
              <tbody>
                <tr>
                  <td>
                    <select
                      onChange={(event) => handleOnChange_gateway(event, gatewayIndex)}
                      value={device.gatewayID}
                    >
                      {(listOfGateways!==null)&&listOfGateways.map((thisItem, gwListIndex) => (
                        <option key={gwListIndex}>{thisItem.gatewayID}</option>
                      ))}
                    </select>
                  </td>
                  
                  <td>
                    <button className = 'defaultButton' type="button" onClick={() => deleteDevice(gatewayIndex)}>
                      Delete Device
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
            </div>
          </div>
        ):(null)
        ))} 
      </div>
      <button className = "formButton" type="button" onClick={addDevice}>
          Add Device
        </button>
        <button className = "formButton" type="button" onClick={handleSubmit}>Save Data</button>
      <pre id="jsonOutput"></pre>
    </> 
  );
};

export default SiteGatewaySelect;