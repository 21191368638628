import { SemiLiveDataContext } from '../context/SemiLiveDataContext'
import { useContext } from 'react'

export const useSemiLiveDataContext = () => {
  const context = useContext(SemiLiveDataContext)

  if (!context) {
    throw Error('useSemiLiveDataContext must be used inside an SemiLiveDataContextProvider')
  }

  return context
}