import React, { useEffect, useRef } from 'react'
import ReactDOMServer from 'react-dom/server';
import { useStaticDataContext } from '../../hooks/useStaticDataContext'
import { useSemiLiveDataContext } from '../../hooks/useSemiLiveDataContext';
import { unitConverter } from '../functionalComponents/unitConverter';
import { toSigFigs } from '../functionalComponents/sigFig';
const echarts = require('echarts')

// note that the argument is only for one gateway. another gateways should be on another chart
const DataChart = React.memo(({argument})=>{
  const { liveChart } = useSemiLiveDataContext(); 
  const { isLanscape } = useStaticDataContext()
  const chartRef = useRef(null);
  const unit = liveChart?.[argument?.siteID]?.[argument?.chartSelect?.split('.')[0]]?.displayInfo[argument?.chartSelect]?.dashUnit
  
  const toolTipFunction=(params)=>{
    return `<div>${params[0].axisValueLabel}</div><div>${liveChart[argument.siteID][argument.chartSelect.split('.')[0]].displayInfo[argument.chartSelect].dashName}  ${params[0].value[1]?params[0].value[1].toFixed(2):null} ${unit}</div>`
  }

  let newData = cropChartDataX(argument?.data?.data,80,true)
  let newObj = {...argument.data}
  newObj.data = newData
  if(newData)argument.data.data = newData

  
  useEffect(() => {
    // Initialize the chart when the component mounts
    const Chart = echarts.init(chartRef.current);

    const option = { // #9aaf9a
      grid:{
        left:argument.clean?0:10,
        right:0,
        bottom:3,
        top:argument.clean?0:15,
        containLabel: argument.clean?false:true
      },
      legend:{
        left: 10,
        top: '20rem',
        type: 'scroll',
        orient: 'horizontal',
        show:argument.clean?false:false
      },
      animation: false,
      tooltip: isLanscape?{
        trigger: 'axis', // or 'item' for other types of charts
        axisPointer: {
            type: 'cross' // or 'line' or 'shadow' or other pointer types
        },
        formatter: function(params) {
            return toolTipFunction(params);
        },
        backgroundColor: 'rgba(255, 255, 255, 0.9)', // Tooltip background color
        position: function (pos, params, el, elRect, size) {
          var obj = { top: 10 };
          obj[['left', 'right'][+(pos[0] < size.viewSize[0] / 2)]] = 30;
          return obj;
        }
    }:{
        trigger: 'axis',
      },
      toolbox: {
        feature: {
          dataZoom: {
            yAxisIndex: 'none'
          },
          restore: {},
          saveAsImage: {}
        },
        show:argument.clean?false:true
      },
      xAxis: {
        type: 'time',
        axisLine:{
          show:false
        },
        axisTick:{
          show:false
        },
        axisLabel:{
          margin:argument.clean?-10:10,
          formatter: (value, index) => {
            const holdDate = new Date(value).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });
            return ((holdDate==='24:00'||holdDate==='00:00'))?'00:00':holdDate
          },
        },
        show:argument.clean?true:true
      },
      yAxis: {
        type: 'value',
        boundaryGap: [0, '100%'],
        show:argument.clean?false:true,
        splitLine:{show:false},
        axisLabel:{
          formatter:(value,index)=>{
            const holdValue = unitConverter(value,unit)
            const intHold = holdValue?.value.toFixed()
            if(holdValue?.value%intHold)return ''
            return `${holdValue.value}${holdValue.unit}`
          }
        },
        max:'dataMax'
      },
      
      series: newObj,
      connectNulls: false,
    };

    // Set the option and render the chart
    Chart.setOption(option);
    
    // Ensure the chart is properly disposed of when the component unmounts
    return () => {
      Chart.dispose();
    };
  }, [argument]);

  return <div ref={chartRef} style={{ width: '100%', height: argument.clean?'40vh':'100%' }} />;

})

export default DataChart;

function cropChartDataX(data,threshold,reflect){
  if(!data)return null
  let returnArray = [];
  const dataCount = data.length;
  let offsetStart = 0;
  let upTo = 0;
  let index = 0;
  let halCnt = dataCount/2;
  while(index<halCnt&&Math.abs(data[index++][1])<threshold){}
  if(!reflect){
    offsetStart = index;
    index = dataCount-1
    while(index>halCnt&&Math.abs(data[index--][1])<threshold){}
    upTo = index;
  }
  upTo = dataCount - offsetStart
  index = 0;
  for(let i = offsetStart;i<upTo;i++){
    returnArray[index++] = data[i]
  }
  return returnArray
}