import React from 'react';
import lImg from '../../images/loading.svg';

function InlineLoading() {
    return <img style={{height:'4rem', width:'auto', margin:'-1rem'}} src={lImg} alt="Loading"/>;
}

function ComponentLoading() {
    return (
        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
            <img style={{ maxHeight:'40rem',height:'50%', width:'auto' }} src={lImg} alt="Loading"/>
        </div>
    );
}

export { InlineLoading, ComponentLoading };
