import { useEffect }from 'react'
import { useUserContext } from "../../hooks/useUserContext"
import { useAuthContext } from "../../hooks/useAuthContext"
import Signup from '../../components/Signup'

// components
import UserDetails from '../../components/user/UserDetails'
import { useNavContext } from '../../hooks/useNavContext'
//import SiteForm from '../components/SiteForm'

const UserManage = () => {
  const {dispatchNav} = useNavContext()
  useEffect(()=>{
    dispatchNav({type:'NAV_INDEX',payload:'userMan'})
  },[])
  const {users, dispatchUser} = useUserContext()
  const {user} = useAuthContext()
  const rootUrl = window.location.origin; // Get the root URL dynamically

  useEffect(() => {
    const fetchUsers = async () => {
      const response = await fetch(`${rootUrl}/api/user`, {
        headers: {'Authorization': `Bearer ${user.token}`},
      })
      const json = await response.json()
   
      if (response.ok) {
        dispatchUser({type: 'SET_USERS', payload: json})
      }
    }

    if (user) {
      fetchUsers()
    }
  }, [dispatchUser, user])

  return (
    
    <div className="site">
      <div className="sites">
      <div className='site-container'>
          {users && users.map((users) => (
            <UserDetails key={users._id} users={users} />
          ))}
        </div>
      </div>
      <Signup/>
    </div>
  )
}

export default UserManage