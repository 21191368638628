import { UserSiteContext } from '../context/UserSiteContext'
import { useContext } from 'react'

export const useUserSiteContext = () => {
  const context = useContext(UserSiteContext)

  if (!context) {
    throw Error('useUserSiteContext must be used inside an UserSiteContext')
  }

  return context
}