const unitObject = {
    P: 1e15,
    T: 1e12,
    G: 1e9,
    M: 1e6,
    k: 1e3,
    h: 1e2,
    da: 1e1,
    d: 1e-1,
    c: 1e-2,
    m: 1e-3,
    μ: 1e-6,
    n: 1e-9,
    p: 1e-12,
    f: 1e-15
};

const preferredOutputUnits = {
    larger: ['k', 'M', 'G', 'T', 'P'],
    smaller: ['m', 'μ', 'n', 'p', 'f']
};

export function unitConverter(value, originalUnit) {

    let baseUnit = originalUnit;
    // Convert to base unit if a prefix is found
    if (!/^(PU|pU|Pu|pu)$/.test(originalUnit)) {
        const prefixMatch = /^(P|T|G|M|k|h|da|d|c|m|μ|n|p|f)/.exec(originalUnit);
        if (prefixMatch) {
            const prefix = prefixMatch[0];
            value *= unitObject[prefix];
            baseUnit = originalUnit.replace(prefix, '');
        }
    }
    

    // Determine the most suitable prefix
    const combine = [...preferredOutputUnits.larger, ...preferredOutputUnits.smaller]
    for (const key of combine) {
        const factor = unitObject[key];
        const newValue = value / factor;
        if (Math.abs(newValue) >= 1 && Math.abs(newValue) < 1000) {
            return { value: newValue, unit: key + baseUnit };
        }
    }
    return { value, unit: baseUnit };
}
