import { Link } from 'react-router-dom'
import React, { useContext } from 'react'
import { useLogout } from '../hooks/useLogout'
import { useAuthContext } from '../hooks/useAuthContext'
import logoDesktop from '../images/Energyneering_Clean.png'
import logoMobile from '../images/Energyneering_Cube_Clear.png'
import {useNavContext} from '../hooks/useNavContext'


const InfoBanner = ({argument: role}) => {
  const  { navIndex,dispatchNav } = useNavContext()
  const { logout } = useLogout()
  const { user } = useAuthContext()
  const handleClick = () => {
    // set the state
    dispatchNav({type:'SIDEBAR_STATE',payload:false})
    logout()
  }
  
  return (
    <header>
      <div className="container">
        <Link to="/">
        <img
            src={window.innerWidth < 600 ? logoMobile : logoDesktop}
            className="navLogo"
            alt="Logo"
          />
        </Link>
        
          
          {user && (
            <div>
              {/*<span>{user.email}</span> this is removed for now */} 
                        
              <button className = 'defaultButton' onClick={handleClick}>Log out</button>    
              {/*<span>{role}</span> This is remove for now*/}         
            </div>
          )}
          {!user && (
            <div>
              <a href="https://www.energyneering.co.za">Company website</a>
            </div>
          )}          
        
      </div>
    </header>
  )
}

export default InfoBanner