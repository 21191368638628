import { createContext, useReducer } from 'react';

export const UsersContext = createContext();

export const usersReducer = (state, action) => {
  switch (action.type) {
    case 'SET_USERS':
      return {
        ...state,
        users: action.payload
      };
    case 'CREATE_USER':
      return {
        ...state,
        users: [action.payload, ...state.users]
      };
    case 'DELETE_USER':
      return {
        ...state,
        users: state.users.filter((user) => user._id !== action.payload._id)
      };
    case 'SET_FORM_FLAG':
      return{
        ...state,
        formFlag: action.payload
      }
    case 'SET_FORMOPEN_FLAG':
      return{
        ...state,
        fromOpenFlag: action.payload
      }
    case 'SET_USER_ROLE':
      return{
        ...state,
        userRole: action.payload
      }
    case 'SET_SELECTED_SITE':
      return{
        ...state,
        activeSiteData: action.payload
      }
    default:
      return state;
  }
};

export const UsersContextProvider = ({ children }) => {
  
  const [state, dispatchUser] = useReducer(usersReducer, {
    users: null
  });

  return (
    <UsersContext.Provider value={{ ...state, dispatchUser }}>
      {children}
    </UsersContext.Provider>
  );
};
