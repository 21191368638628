import { useState } from "react"
import { useSignup } from "../hooks/useSignup"

const Signup = () => {
  const [name, setName] = useState('')
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const {signup, error, isLoading} = useSignup()
  const [role, setRole] = useState('user')

  const handleSubmit = async (e) => {
    e.preventDefault()

    await signup(name, email, password, role)
  }

  const roles = ['client','user','admin','developer']; // Array of available roles

  return (
    <form className="signup" onSubmit={handleSubmit}>
      <h3>Sign Up A New User</h3>
      
      <label>User Name:</label>
      <input 
        type="name" 
        onChange={(e) => setName(e.target.value)} 
        value={name} 
      />

      <label>Email address:</label>
      <input 
        type="email" 
        onChange={(e) => setEmail(e.target.value)} 
        value={email} 
      />
      <label>Password:</label>
      <input 
        type="password" 
        onChange={(e) => setPassword(e.target.value)} 
        value={password} 
      />

      <label>Role:</label>
      <select value={role} onChange={(e) => setRole(e.target.value)}>
        {roles.map((role) => (
          <option key={role} value={role}>
            {role}
          </option>
        ))}
      </select>

      <div>
        <br/>     
        <button className = 'defaultButton' disabled={isLoading}>Sign up</button>
      </div> 
      {error && <div className="error">{error}</div>}
    </form>
  )
}

export default Signup