import { createContext, useReducer } from 'react';

export const StaticDataContext = createContext();

export const staticDataReducer = (state = { staticData: null }, action) => {
  switch (action.type) {
    case 'TIME_ZONE_ERR': 
      return {
        ...state,
        timeZoneErr: action.payload,
      };  
    case 'TIME_ZONE_OFFSET': 
      return {
        ...state,
        timeOffset: action.payload,
      }; 
    case 'SCREEN_STATE': 
      return {
        ...state,
        isLanscape: action.payload,
      };
    case 'USER_SELECTED_SITE':
      return{
        ...state,
        userSelectedSite: action.payload
      }
    case 'WS_CLASS':{
      return{
        ...state,
        wsClass: action.payload
      }
    }break;
    
    default:
      return state;
  }
};

export const StaticDataContextProvider = ({ children }) => {
  const [state, dispatchStaticData] = useReducer(staticDataReducer, {
    staticData: null,
  });

  return (
    <StaticDataContext.Provider value={{ ...state, dispatchStaticData }}>
      {children}
    </StaticDataContext.Provider>
  );
};
