import { useEffect, useState } from 'react';
import { useSitesContext } from '../../hooks/useSitesContext';
import { useAuthContext } from '../../hooks/useAuthContext';
import jwtDecode from 'jwt-decode';

import DynamicForm from "./SiteManageForm.js"
// date fns
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

const SiteDetails = ({ site }) => {
  const { dispatch } = useSitesContext();
  const { user } = useAuthContext();
  const payload = jwtDecode(user.token);

  const [isEditing, setIsEditing] = useState(false);
  const [editSiteName, setEditSiteName] = useState(site.siteName);
  const [editSiteID, setEditSiteID] = useState(site.siteID);
  const [showPrompt, setShowPrompt] = useState(false); // Add state for prompt visibility

  const [isFormOpen, setIsFormOpen] = useState(false); // New state for form visibility 
  const {formFlag, fromOpenFlag } = useSitesContext();
  const rootURL = window.location.origin;


  const deleteSite = async () => {
    if (!user || payload.role !== 'admin') {
      return;
    }

    const response = await fetch(`${rootURL}/api/sites/` + site._id, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();

    if (response.ok) {
      dispatch({ type: 'DELETE_SITE', payload: json });
    }
  };

  const handleEditClick = () => {
    setIsEditing(true);
  };

  const handleDeleteClick = () => {
    setShowPrompt(true); // Show the prompt
  };

  const handleConfirmDelete = () => {
    setShowPrompt(false); // Hide the prompt
    deleteSite(); // Proceed with site deletion
  };

  const handleCancelDelete = () => {
    setShowPrompt(false); // Hide the prompt
  };

  const handleConfirmEdit = () => {
    const updatedSite = {
      ...site,
      siteName: editSiteName,
      siteID: editSiteID
    };

    fetch(`${rootURL}/api/sites/${site._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
      body: JSON.stringify(updatedSite)
    })
      .then(response => response.json())
      .then(json => {
        dispatch({ type: 'UPDATE_SITE', payload: json });
        setIsEditing(false);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
    setEditSiteName(site.siteName);
    setEditSiteID(site.siteID);
  };

  // config form functions
  const openForm = () => {
    dispatch({ type: 'SET_FORM_FLAG', payload: site.siteID });
    setIsFormOpen(true);
  };

  const handleSettingsClick = (e)=>{
    
    e.preventDefault();

    openForm();
  }
  

  return (
    <>
    {(formFlag==site.siteID)  &&<DynamicForm site={site}></DynamicForm>}
      <div className="manage-details">
        <h4>
          {isEditing ? (
            <input
              type="text"
              value={editSiteName}
              onChange={e => setEditSiteName(e.target.value)}
            />
          ) : (
            site.siteName
          )}
        </h4>
        <p>
          <strong>Site ID: </strong>
          {isEditing ? (
            <input
              type="text"
              value={editSiteID}
              onChange={e => setEditSiteID(e.target.value)}
            />
          ) : (
            site.siteID
          )}
        </p>      
        <p>{formatDistanceToNow(new Date(site.createdAt), { addSuffix: true })}</p>
        <div>
          {user && payload.role === 'admin' && (
            <>
              {isEditing ? (
                <>
                  <button className = 'defaultButton' onClick={handleConfirmEdit}>Save</button>
                  <button className = 'defaultButton' onClick={handleCancelEdit}>Cancel</button>
                </>
              ) : (
                <>
                  <span className="material-symbols-outlined" onClick={handleSettingsClick}>
                    settings
                  </span>
                  <span className="material-symbols-outlined" onClick={handleDeleteClick}>
                    delete
                  </span>
                  <span className="material-symbols-outlined" onClick={handleEditClick}>
                    edit
                  </span>
                </>
              )}
            </>
          )}
        </div>

        {showPrompt && (
          <div className="prompt">
            <p>Are you sure you want to delete this site?</p>
            <button className = 'defaultButton' onClick={handleConfirmDelete}>Yes</button>
            <button className = 'defaultButton' onClick={handleCancelDelete}>No</button>
          </div>
        )}
      </div>
    </>
  );
};

export default SiteDetails;
