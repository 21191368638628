import { useAuthContext } from './useAuthContext'
import { useSitesContext } from './useSitesContext'

export const useLogout = () => {
  const { dispatch } = useAuthContext()
  const { dispatch: dispatchSites } = useSitesContext()

  const logout = () => {
    // remove user from storage
    localStorage.removeItem('user')

    // dispatch logout action
    dispatch({ type: 'LOGOUT' })
    dispatchSites({ type: 'SET_SITES', payload: null })
  }

  return { logout }
}