import React,{ useEffect, useState } from 'react';
import { useSitesContext } from '../../hooks/useSitesContext';
import { useAuthContext } from '../../hooks/useAuthContext';
import jwtDecode from 'jwt-decode';
import ConfigureControlButtons from './ConfigureControlButtons';

// date fns
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

const ConfigureControlInputSiteSelect_details = ({ site }) => {
const { user } = useAuthContext();
const payload = jwtDecode(user?.token);
const [formFlag,setFormFlag] = useState(false);
const [isEditing, setIsEditing] = useState(false);
const [editSiteName, setEditSiteName] = useState(site?.siteName);
const [editSiteID, setEditSiteID] = useState(site?.siteID);
const rootURL = window.location.origin;

  
  return (
    <>
      <div className="manage-details">
        <h4>
          {isEditing ? (
            <input
              type="text"
              value={editSiteName}
              onChange={e => setEditSiteName(e.target.value)}
            />
          ) : (
            site.siteName
          )}
        </h4>
        <p>
          <strong>Site ID: </strong>
          {isEditing ? (
            <input
              type="text"
              value={editSiteID}
              onChange={e => setEditSiteID(e.target.value)}
            />
          ) : (
            site.siteID
          )}
        </p>      
        <p>{formatDistanceToNow(new Date(site?.createdAt), { addSuffix: true })}</p>
        <div>
          {user && payload?.role === 'admin' && (
            <>
              {(
                <>
                  <button className = 'defaultButton' onClick={()=>setFormFlag(true)}>Configure Control</button>
                </>
              )}
            </>
          )}
        </div>
        {formFlag?<div className='form-popup-bg is-visible' >
            <button className = 'defaultButton' onClick={()=>setFormFlag(false)} style={{ position: 'absolute', top: 0, right: '0.3rem' }}>Esc</button>
            <div className="form-container" style = {{height:'75vh'}}>
                
                <ConfigureControlButtons gateways={site.gateways} siteInfo={site}/>
            </div>
            
        </div>:<></>}
      </div>
    </>
  );
};



export default React.memo(ConfigureControlInputSiteSelect_details);
