import { createContext, useReducer } from 'react'

export const UserSiteContext = createContext()

export const userSiteReducer = (state, action) => {
  switch (action.type) {
    case 'USER_SITES': 
      return {
        userSite: action.payload
      }
    case 'CREATE_SITE':
      return {
        userSite: [action.payload, ...state.userSite]
      }
    default:
      return state
  }
}

export const UserSiteContextProvider = ({ children }) => {
  const [state, dispatchUserSite] = useReducer(userSiteReducer, {
    userSite: null
  })

  return (
    <UserSiteContext.Provider value={{...state, dispatchUserSite}}>
      { children }
    </UserSiteContext.Provider>
  )
}