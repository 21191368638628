import React, { useState, useEffect } from 'react';
import { useSitesContext } from '../../hooks/useSitesContext';
import SiteGatewaySelect from './SiteGatewaySelect';
import UploadMedia from './SiteUploadMedia';

const DynamicForm = ({ site }) => {
  const {sites, dispatch} = useSitesContext()
  const selection=['Select Gateways','Upload Image']
  const [selectConfig,setSelectConfig] = useState(selection[0]);
  const closeForm = () => {
    dispatch({ type: 'SET_FORM_FLAG', payload: null });
    dispatch({ type: 'SET_FORMOPEN_FLAG', payload: null });
  };

  return (
    <div className="form-popup-bg is-visible">
      <div className="form-container">
        <h1>{"Configure: " + site.siteID}</h1>
        <button className="close-button" onClick={closeForm}>X</button>
        <select value={selectConfig} onChange={(e)=>{setSelectConfig(e.target.value)}}>{selection.map((item, index) => <option key={index}>{item}</option>)}</select>
        {selectConfig === selection[1]?<UploadMedia site={site}/>:<></>}
        {selectConfig === selection[0] ? <SiteGatewaySelect site={site} /> : <></>}
      </div>
    </div>
  )
};

export default DynamicForm;