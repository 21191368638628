import { createContext, useReducer } from 'react'

export const SitesContext = createContext()

export const sitesReducer = (state, action) => {
  switch (action.type) {
    case 'SET_SITES': 
      return {
        ...state,
        sites: action.payload
      }
    case 'CREATE_SITE':
      return {
        ...state,
        sites: [action.payload, ...state.sites]
      }
    case 'DELETE_SITE':
      return {
        ...state,
        sites: state.sites.filter((w) => w._id !== action.payload._id)
      }
    case 'SET_FORM_FLAG':
      return{
        ...state,
        formFlag: action.payload
      }
    case 'SET_FORMOPEN_FLAG':
      return{
        ...state,
        fromOpenFlag: action.payload
      }
    case 'SET_GW_PARAM':
      return{
        ...state,
        gatewayParam: action.payload
      }
    default:
      return state
  }
}

export const SitesContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(sitesReducer, {
    sites: null
  })

  return (
    <SitesContext.Provider value={{...state, dispatch}}>
      { children }
    </SitesContext.Provider>
  )
}