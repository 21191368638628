import { useState } from "react"
import { useSitesContext } from "../../hooks/useSitesContext"
import { useAuthContext } from '../../hooks/useAuthContext'

const SiteForm = () => {
  const { dispatch } = useSitesContext()
  const { user } = useAuthContext()

  const [siteName, setSiteName] = useState('')
  const [siteID, setSiteID] = useState('')
  const [error, setError] = useState(null)
  const [emptyFields, setEmptyFields] = useState([])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!user) {
      setError('You must be logged in')
      return
    }

    const site = {siteName, siteID}
    const rootUrl = window.location.origin; // Get the root URL dynamically

    const response = await fetch(`${rootUrl}/api/sites`, {
      method: 'POST',
      body: JSON.stringify(site),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    })
    const json = await response.json()

    if (!response.ok) {
      setError(json.error)
      setEmptyFields(json.emptyFields)
    }
    if (response.ok) {
      setSiteName('')
      setSiteID('')
      setError(null)
      setEmptyFields([])
      dispatch({type: 'CREATE_SITE', payload: json})
    }
  }

  return (
    <form className="create" onSubmit={handleSubmit}>
      <h3>Add a New Site</h3>

      <label>Site Name</label>
      <input 
        type="text"
        onChange={(e) => setSiteName(e.target.value)}
        value={siteName}
        className={emptyFields.includes('siteName') ? 'error' : ''}
      />

      <label>Site ID:</label>
      <input 
        type="text"
        onChange={(e) => setSiteID(e.target.value)}
        value={siteID}
        className={emptyFields.includes('siteID') ? 'error' : ''}
      />
      <button className = 'defaultButton'>Add Site</button>
      {error && <div className="error">{error}</div>}
    </form>
  )
}

export default SiteForm