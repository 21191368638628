import { useState } from "react"
import { useGatewaysContext } from "../../hooks/useGatewaysContext"
import { useAuthContext } from '../../hooks/useAuthContext'

const GatewayForm = () => {
  const { dispatch } = useGatewaysContext()
  const { user } = useAuthContext()

  
  const [gatewayID, setGatewayID] = useState('')
  const [error, setError] = useState(null)
  const [emptyFields, setEmptyFields] = useState([])

  const handleSubmit = async (e) => {
    e.preventDefault()

    if (!user) {
      setError('You must be logged in')
      return
    }

    const gateway = {gatewayID}
    const rootUrl = window.location.origin; // Get the root URL dynamically
    const response = await fetch(`${rootUrl}/api/gateways`, {
      method: 'POST',
      body: JSON.stringify(gateway),
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    })
    const json = await response.json()

    if (!response.ok) {
      setError(json.error)
      setEmptyFields(json.emptyFields)
    }
    if (response.ok) {
      setGatewayID('')
      setError(null)
      setEmptyFields([])
      dispatch({type: 'CREATE_GATEWAY', payload: json})
    }
  }

  return (
    <form className="create" onSubmit={handleSubmit}>
      <h3>Add a New Gateway</h3>

      <label>Gateway ID:</label>
      <input 
        type="text"
        onChange={(e) => setGatewayID(e.target.value)}
        value={gatewayID}
        className={emptyFields?.includes('gatewayID') ? 'error' : ''}
      />
      <button className = 'defaultButton'>Add Gateway</button>
      {error && <div className="error">{error}</div>}
    </form>
  )
}

export default GatewayForm