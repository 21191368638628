import { useSitesContext } from "../../hooks/useSitesContext"
import { useAuthContext } from "../../hooks/useAuthContext"
import { useNavContext } from "../../hooks/useNavContext"
import { useEffect } from "react"
// components
import ConfigureControlInputSiteSelect_details from './ConfigureControlInputSiteSelect_details'

const ConfigureControlInputSiteSelect = () => {
  const {dispatchNav} = useNavContext()
  useEffect(()=>{
    dispatchNav({type:'NAV_INDEX',payload:'configControlSelect'})
  },[])
  const {sites, dispatch} = useSitesContext()
  const {user} = useAuthContext()
  return (
    <>
        <div className="site">
        <div className="sites">
            <div className='site-container'>
            {sites && sites.map((site) => (
                <ConfigureControlInputSiteSelect_details key={site?._id} site={site}/>
            ))}
            </div>
        </div>
        </div>
        
    </>
  )
}

export default ConfigureControlInputSiteSelect