import { useRef,useEffect } from 'react'
import { Link } from 'react-router-dom'
import { useAuthContext } from '../hooks/useAuthContext'
import { useUserContext } from '../hooks/useUserContext'
import { useStaticDataContext } from '../hooks/useStaticDataContext'
import { useNavContext } from '../hooks/useNavContext'

const NavBar = () => { // Corrected the parameter syntax
  const  { navIndex,dispatchNav } = useNavContext()
  const { userSelectedSite,dispatchStaticData,wsClass } = useStaticDataContext();
  const {userRole,dispatchUser} = useUserContext()
  const navbarRev = useRef(null)
  const { user } = useAuthContext()

  function navTrack(tabName){
    dispatchNav({type:'SIDEBAR_STATE',payload:false})
    switch(tabName){
      case 'Home':{
        // dispatchStaticData({type:'USER_SELECTED_SITE',payload:null})
        // dispatchUser({type:'SET_SELECTED_SITE',payload:null})
        // if(wsClass?.ws)wsClass?.ws.close()
        // if(wsClass?.timerID)clearInterval(wsClass?.timerID)
      }break;
    }
  }

  
  return (
    <nav ref={navbarRev}>
        {user && (
          <ul className="nav-container">
      {(userRole === 'user' || userRole === 'admin' || userRole === 'client') && (
        <li className={`${navIndex==='Home'?'active':''}`}>
          <Link onClick={() => navTrack('Home')}  className="navLink" to="/">
            Home
          </Link>
        </li>
      )}

      {userSelectedSite && (userRole === 'user' || userRole === 'admin' || userRole === 'client') ? (
        <li className={`${navIndex==='dash'?'active':''}`} >
          <Link onClick={() => navTrack('dash')} className="navLink" to="/dashpage">
            Dashboard
          </Link>
        </li>
      ) : null}

      {userSelectedSite && (userRole === 'user' || userRole === 'admin' || userRole === 'client') ? (
        <li className={`${navIndex==='dataExp'?'active':''}`} >
          <Link onClick={() => navTrack('dataExp')} className="navLink" to="/dataexplorepage">
            Data Explore
          </Link>
        </li>
      ) : null}

      {userSelectedSite && (userRole === 'admin') ? (
        <li className={`${navIndex==='control'?'active':''}`} >
          <Link onClick={() => navTrack('control')} className="navLink" to="/sitecontrol">
            Control
          </Link>
        </li>
      ) : null}

      {userSelectedSite && ( userRole === 'admin') ? (
        <li className={`${navIndex==='dashMan'?'active':''}`} >
          <Link onClick={() => navTrack('dashMan')} className="navLink" to="/dashboardmanage">
            Dashboard Manage
          </Link>
        </li>
      ) : null}

      {userRole === 'admin' && (
        <li className={`${navIndex==='siteMan'?'active':''}`} >
          <Link onClick={() => navTrack('siteMan')} className="navLink" to="/sitemanage">
            Site Manage
          </Link>
        </li>
      )}

      {userRole === 'admin' && (
        <li className={`${navIndex==='userMan'?'active':''}`} >
          <Link onClick={() => navTrack('userMan')} className="navLink" to="/usermanage">
            User Manage
          </Link>
        </li>
      )}

      {userRole === 'admin' && (
        <li className={`${navIndex==='gatewayMan'?'active':''}`} >
          <Link onClick={() => navTrack('gatewayMan')} className="navLink" to="/gatewaymanage">
            Gateway Manage
          </Link>
        </li>
      )}

      {userRole === 'admin' && (
        <li className={`${navIndex==='configControlSelect'?'active':''}`} >
          <Link onClick={() => navTrack('configControlSelect')} className="navLink" to="/configcontrolinputselect">
            Configure Control
          </Link>
        </li>
      )}

      {(userRole === 'user' || userRole === 'admin' || userRole === 'client') && false && (
        <>
          <br />
          {userRole}
        </>
      )}
</ul>

        )}
  </nav>
    
  )
}

export default NavBar
