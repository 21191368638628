import ListOfSites from '../../components/homePage/ListOfSites';

const DashboardSiteSelect = () => {
  

    return(<div className="site">
      <ListOfSites parameter = {'dataExplore'}/>
  </div>
       
    )
}

export default DashboardSiteSelect