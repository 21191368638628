import React, { useState, useEffect } from 'react';
import { useGatewaysContext } from '../../hooks/useGatewaysContext';
import { useAuthContext } from '../../hooks/useAuthContext';
import jwtDecode from 'jwt-decode';
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

import DynamicForm from "./gateWayDriverForm.js"
import LocationForm from "./LocationForm.js"

const GatewayDetails =  ({ gateway }) => {
  
  const { dispatch, formFlag, formLocationFlag } = useGatewaysContext();
  const { user } = useAuthContext();
  const payload = jwtDecode(user.token);

  const [isEditing, setIsEditing] = useState(false);
  const [editGatewayID, setEditGatewayID] = useState(gateway.gatewayID);
  const [showPrompt, setShowPrompt] = useState(false);
  const [isFormOpen, setIsFormOpen] = useState(false); // New state for form visibility  
  const rootURL = window.location.origin;
  

  //For the form:
  //const [inputRows, setInputRows] = useState(dataFromDatabase);
  const [inputRows, setInputRows] = useState([[{ value: 'Device_Key' }, { value: 'Unit_Of_Measure' }, { value: 'Description' }, { value: 'accessLevel' }]]);

  const deleteGateway = async () => {
    if (!user || payload.role !== 'admin') {
      return;
    }

    const response = await fetch(`${rootURL}/api/gateways/` + gateway._id, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();

    if (response.ok) {
      dispatch({ type: 'DELETE_GATEWAY', payload: json });
    }
  };
  

  const handleDeleteClick = () => {
    setShowPrompt(true); // Show the prompt
  };

  const handleConfirmDelete = () => {
    setShowPrompt(false); // Hide the prompt
    deleteGateway(); // Proceed with gateway deletion
  };

  const handleCancelDelete = () => {
    setShowPrompt(false); // Hide the prompt
  };  

 

  const openForm = () => {
    dispatch({ type: 'SET_FORM_FLAG', payload: gateway.gatewayID });
    setIsFormOpen(true);
  };

  const openLocationForm = async () => {
    dispatch({ type: 'SET_LOCATION_FORM_FLAG', payload: gateway.gatewayID });
  };

  

  const handleSettingsClick = (event) => {
    setInputRows([
      [
          { value: 'Device_Key' },
          { value: 'Unit_Of_Measure' },
          { value: 'Description' },
          { value: 'accessLevel' },
      ],
  ]);
    event.preventDefault();
    openForm();
  };

  
  return (
  <> 
    {(formLocationFlag==gateway.gatewayID)&&<LocationForm gateway={gateway}></LocationForm>} 
    {(formFlag==gateway.gatewayID)  &&<DynamicForm gateway={gateway}></DynamicForm>}    

<div className="manage-details">      
      <p>
        <strong>Gateway ID: </strong>
        {isEditing ? (
          <input
            type="text"
            value={editGatewayID}
            onChange={e => setEditGatewayID(e.target.value)}
          />
        ) : (
          gateway.gatewayID
        )}
      </p>
      <p>{formatDistanceToNow(new Date(gateway.createdAt), { addSuffix: true })}</p>
      <div>
        {user && payload.role === 'admin' && (
          <>
            {
              <>
                <span className="material-symbols-outlined" onClick={handleSettingsClick}>
                  settings
                </span>
                <span className="material-symbols-outlined" onClick={openLocationForm}>
                  location_on
                </span>
                <span className="material-symbols-outlined" onClick={handleDeleteClick}>
                  delete
                </span>                
              </>
            }
          </>
        )}
      </div>

      {showPrompt && (
        <div className="prompt">
          <p>Are you sure you want to delete this gateway?</p>
          <button className = 'defaultButton' onClick={handleConfirmDelete}>Yes</button>
          <button className = 'defaultButton' onClick={handleCancelDelete}>No</button>
        </div>
      )}
    </div>
    </>
    
  );
};

export default GatewayDetails;
