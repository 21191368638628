import { LiveDataContext } from '../context/LiveDataContext'
import { useContext } from 'react'

export const useLiveDataContext = () => {
  const context = useContext(LiveDataContext)

  if (!context) {
    throw Error('useLiveDataContext must be used inside an LiveDataContextProvider')
  }

  return context
}