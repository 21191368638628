import { StaticDataContext } from '../context/StaticDataContext'
import { useContext } from 'react'

export const useStaticDataContext = () => {
  const context = useContext(StaticDataContext)

  if (!context) {
    throw Error('useStaticDataContext must be used inside an StaticDataContextProvider')
  }

  return context
}