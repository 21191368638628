import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import { BrowserRouter } from 'react-router-dom';
import { SitesContextProvider } from './context/SiteContext'
import { GatewaysContextProvider } from './context/GatewayContext'
import { UsersContextProvider } from './context/UserContext';
import { AuthContextProvider } from './context/AuthContext';
import { StaticDataContextProvider } from './context/StaticDataContext';
import { LiveDataContextProvider } from './context/LiveDataContext';
import { SemiLiveDataContextProvider } from './context/SemiLiveDataContext'
import { UserSiteContextProvider } from './context/UserSiteContext';
import { NavContextProvider } from './context/NavContext';
import ErrorBoundary from './components/ErrorBoundry.js'

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <ErrorBoundary>
    <BrowserRouter>
      <AuthContextProvider>
        <UserSiteContextProvider>
          <StaticDataContextProvider>
            <SemiLiveDataContextProvider>
              <LiveDataContextProvider>
                <UsersContextProvider>
                  <SitesContextProvider>
                    <GatewaysContextProvider>
                      <NavContextProvider>
                        <App />
                      </NavContextProvider>
                    </GatewaysContextProvider>
                  </SitesContextProvider>
                </UsersContextProvider>
              </LiveDataContextProvider>
            </SemiLiveDataContextProvider>
          </StaticDataContextProvider>
        </UserSiteContextProvider>
      </AuthContextProvider>
      </BrowserRouter>
  </ErrorBoundary>
);