import React, { useState,useRef,useEffect } from "react";
import { useAuthContext } from "../../hooks/useAuthContext";

const UploadMedia=({site})=>{
  const [result, setResult] = useState('');
    
    const API_URL = window.location.origin
    const fileInputRef = useRef();
    const {user} = useAuthContext()
    const [file,setFile] = useState()
    const [imageBlob, setImageBlob] = useState({});
    const [imgSetList,setImgSetList] = useState(null)
    const roorURL = window.location.origin
    const uploadFile = async (data) => {
        try {
            const response = await fetch(`${API_URL}/api/sites/media`, {
                method: 'POST',
                body: data,
                headers: {
                  'Authorization': `Bearer ${user.token}`,
                  'sid': `${site.siteID}`
                }
            });
    
            if (!response.ok) {
                throw new Error(`HTTP error! Status: ${response.status}`);
            }
    
            const responseData = await response.json();
            return responseData;
        } catch (error) {
            console.error('Error while calling the API', error.message);
        }
    };
    useEffect(() => {
      // Fetch image blob from the server
      const fetchImageBlob = async () => {
        try {
          const response = await fetch(`${API_URL}/api/sites/images/${'allTheNames'}`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${user.token}`
            },
          });
  
          if (response.ok) {
            const blob = await response.blob();
            setImageBlob(blob);
            alert(JSON?.parse(response)?.message)
          } else {
            console.error('Failed to fetch image:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching image:', error);
        }
      };
  
      fetchImageBlob();

      async function fetchImgSetList(){
        try{
          const response = await fetch(`${API_URL}/api/sites/imagefields`, {
            method: 'GET',
            headers: {
              'Authorization': `Bearer ${user.token}`
            },
          });

          if(response.ok){
            const hold = await response.json()
            setImgSetList(hold)
          }else{
            console.error('Failed to fetch the image field list')
          }
        }catch(e){console.error(e)}
      }
      fetchImgSetList()
    }, []);

    useEffect(()=>{
      const getImage = async () => {
        if(file){
          const data = new FormData();
          data.append("name", file.name);
          data.append("file", file);
          let response = await uploadFile(data);
          setResult(response?.path);
        }
      }
      getImage();
    }, [file])

      
    const onUploadClick = () => {
        fileInputRef.current.click();
      }

    async function handelImgSelection(selected,id){
        try {
        const response = await fetch(`${API_URL}/api/sites/setimagefield`, {
            method: 'POST',
            headers: {
              'Authorization': `Bearer ${user.token}`,
              'sid': `${site.siteID}`,
              'selected': `${selected}`,
              'imgid': `${id}`
            }
        });

        if (!response.ok) {
            throw new Error(`HTTP error! Status: ${response.status}`);
        }

        const responseData = await response.json();
        return responseData;
      } catch (error) {
          console.error('Error while calling the API', error.message);
      }
    }

    const downloadFile = async (url) => {
      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const blobUrl = URL.createObjectURL(blob);
  
        const link = document.createElement('a');
        link.href = blobUrl;
        document.body.appendChild(link);
        link.click();
  
        // Clean up
        URL.revokeObjectURL(blobUrl);
        document.body.removeChild(link);
      } catch (error) {
        console.error('Error downloading file:', error);
      }
    };

    async function deleteImage(id){
      console.log("This is the name of the file which we are deleting:",id)
    }

    useEffect(()=>{
      console.log("This is the site info we are working with:",site)
    },[site])
    

    function imgSelection(imgInfo,i){

      return 'no no'
    }
    return (
        <div className='container'>
          <div className='wrapper'>
            <button onClick={() => onUploadClick()}>Upload</button>
            <table>
              <tbody>
                {site?.media?.img_list?.map((i, n) => (
                  <>
                  <tr key={n}>
                    <td>
                      <img src={`${roorURL}/api/sites/icon/${i}`} style={{height:'6rem'}}/>
                    </td>
                    <td>
                      {imgSetList&&<select onChange={(e)=>{handelImgSelection(e.target.value,i)}}>{imgSetList?.imageFields?.map((i1,n1)=>(
                        <option value={i1}>{imgSetList?.displayField[n1]}</option> 
                      ))}</select>}
                    </td>
                    <td>
                      <button onClick={()=>downloadFile(`${roorURL}/api/sites/icon/${i}`)}>Download</button>
                    </td>
                    <td>
                      <button onClick={()=>deleteImage(i)}>Delete</button>
                    </td>
                  </tr>
                  </>
                ))}
              </tbody>
            </table>
            <input 
              type="file"
              ref={fileInputRef}
              style={{display: 'none'}}
              onChange={(e) => setFile(e.target.files[0])}
            />
          </div>
        </div>
      );
}

export default UploadMedia