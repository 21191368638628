import { Link } from 'react-router-dom'
import { useAuthContext } from '../../hooks/useAuthContext';
import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../hooks/useUserContext';
import './homePage.css'; // Import the CSS file
import { useUserSiteContext } from '../../hooks/useUserSite'
import { useStaticDataContext } from '../../hooks/useStaticDataContext';
import { useNavContext } from '../../hooks/useNavContext';
import { useSitesContext } from '../../hooks/useSitesContext';
import { ComponentLoading } from '../subcomp/InlineLoading';



const ListOfSites = ({parameter}) => {
  const { dispatchStaticData,wsClass } = useStaticDataContext();
  const { userSite, dispatchUserSite } = useUserSiteContext();
  const { user } = useAuthContext();
  const { dispatchUser, activeSiteData } = useUserContext();
  const { dispatchNav } = useNavContext()
  const {sites, dispatch} = useSitesContext()

  const [loading, setLoading] = useState(true); // Add loading state
  const roorURL = window.location.origin

  

  const handleSiteButton = async (e,itemIndex,siteInfo)=>{
    if(true){
      await dispatchUser({ type: 'SET_SELECTED_SITE', payload: siteInfo }); //1
      
      await dispatchStaticData({type: 'USER_SELECTED_SITE',payload:siteInfo.siteID}) //2

      await dispatchNav({type:'NAV_INDEX',payload:'dash'})
    }    
  }
  
  ////////////////////////////////////////////////////
  useEffect(()=>{
    if(userSite){
      if(userSite)setLoading(false)
    }
  },[userSite])

  ////////////////////////////////////////////////////
 

  
  function navFunction(_parameter){
    switch(_parameter){
      case 'chartData':{
        return "/dashpage"
      }
      case 'dashboardConfig':{
        return "/dashboardmanage"
      }
      case 'dataExplore':{
        return '/dataexplorepage'
      }
    }
  }

  const displaySites = () => {
    if (loading) {
      return <ComponentLoading/>;
    }
    return (
      <>
        <main className='cards'>
          <>{user?.name}</>
            <div className='card-container'>
              {userSite?.map((item, itemIndex) => (
                <div key = {`card_${itemIndex}`} className='card' >
                  <div className="card-background">
                    {item?.media?.dashImage ? <img src={`${roorURL}/api/sites/displayImage/${item?.media?.dashImage}`} alt="Card background" />:<></>}
                  </div>
                  <h2>{item?.siteName}</h2>
                  <Link key = {`site_${itemIndex}`} className="navLink" to={navFunction(parameter)}> <button className = 'defaultButton' onClick={(e)=>handleSiteButton(e,itemIndex,{gateways:item?.gateways,siteID:item?.site_id})}>View</button></Link>
                </div>
              
            ))}
          </div>  
        </main>
      </>
    );
  };
  return displaySites();
};

export default ListOfSites;
