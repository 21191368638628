import { GatewaysContext } from '../context/GatewayContext'
import { useContext } from 'react'

export const useGatewaysContext = () => {
  const context = useContext(GatewaysContext)

  if (!context) {
    throw Error('useGatewaysContext must be used inside an GatewaysContextProvider')
  }

  return context
}