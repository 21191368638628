
const NUMBER_OF_PAGES = 16
export const startWS = async (userInfo,sites,dispatchLiveData,liveData)=>{
  const urlString = `${window.location.origin}`
  const siteCount = sites.length 

  let holdDashRequestData = []
  let holdLiveDataStructure = {}
  for(let i = 0;i<siteCount;i++){
    const holdDriverData = await getDriverDataFromServer(sites[i],userInfo,urlString);
    if(holdDriverData){
      if(holdDriverData.dashSettings){
        const holdLivedata = parseLiveData(holdDriverData.dashSettings)
        holdLiveDataStructure = {...holdLiveDataStructure,...holdLivedata.liveDataStructure}
        if(holdLivedata.liveDataStrings===undefined||holdLivedata.liveDataStrings===null)holdLivedata.liveDataStrings = {}
        holdLivedata.liveDataStrings.site = sites[i].site_id
        holdLivedata.liveDataStrings.gateWays = holdDriverData.dashSettings.gateWays
        holdDashRequestData.push(holdLivedata.liveDataStrings)
      }
    }
  }

  return new Promise((resolve, reject) => {
    const ws = new WebSocket(`${urlString.replace('http', 'ws')}/ws`,[userInfo.token]); 
    let intervalId
    let thisValue = {}
    let holdGatewayID = null

    intervalId = setInterval(() => {
      if(holdGatewayID){
        dispatchLiveData({ type: 'DASH_DATA', payload: thisValue })
    };
    }, 500); // todo this should be made a useful value //

    try{
      ws.onmessage = async e => {
        try { 
          const holdJson = await JSON?.parse(e.data)
          const gatewayID = holdJson.gatewayID
          holdGatewayID = gatewayID
          const holdData_0 = holdJson.data
          const page = await JSON?.parse(holdData_0).page
          const holdData = await JSON?.parse(holdData_0).data
          const byteCount = holdData.length
          let holdPrevLiveData = {...thisValue} // the live data is handled like this since it is async, it comes in sets
          if(!holdPrevLiveData[gatewayID])holdPrevLiveData[gatewayID] = {}
          for(let j=0;j<byteCount;j++){
            const ref = `${gatewayID}.${page-1}.${holdData[j][0]}`
            if(holdLiveDataStructure[ref]!==undefined){
              const holdTime = Date.now()
              holdPrevLiveData[gatewayID][`${page-1}.${holdData[j][0]}`] = {time: holdTime,liveData:hexToFloat(holdData[j][1]),displayName:holdLiveDataStructure[ref].displayName,unit:holdLiveDataStructure[ref].unit}
            }  
          }
          thisValue = {...holdPrevLiveData}
          if(thisValue[gatewayID]===undefined)thisValue=null
        }catch(e){
          console.error(e)
        }
      };

      ws.onerror = (error) => {
        clearInterval(intervalId)
        reject(error); // reject the promise if there's an error
       
      };
      
      
      ws.onopen = () => {
        // connection opened
        dispatchLiveData({ type: 'WS_STATUS', payload: true })
        const requestInfo= {type:'dashdata'}
        const holdThisString = JSON.stringify({msgType:'ldInfo',data:holdDashRequestData})
        ws.send(holdThisString);
        const liveDataClass = {ws:ws,timerID:intervalId}
        resolve(liveDataClass);
      };

      ws.onclose = ()=>{
        clearInterval(intervalId)
        dispatchLiveData({ type: 'WS_STATUS', payload: false })
      }
    }catch(e){
      try{
        clearInterval(intervalId)
        reject(`WS connection setup error: ${e.message}`)
      }catch(e){
        console.error(e)
      }
    }
  })
     
}

const parseLiveData=(_dashData)=>{ 
  let returnStruct = null
  let objectFromServer_dash = {}
  if(_dashData){
    let objOfLivePageIndex = {}
     
    let objectFromServer_select = {}
    try{
      const gatewayCount = _dashData.gateWays.length 
      for(let i = 0;i<gatewayCount;i++){ 
        const deviceCount = _dashData[_dashData.gateWays[i]].listOfDevices.length
        for(let j=0;j<deviceCount;j++){
          const paramCount = _dashData[_dashData.gateWays[i]][_dashData[_dashData.gateWays[i]].listOfDevices[j]].listOfParams.length
          
          for(let k = 0;k<paramCount;k++){
            const holdValue = _dashData[_dashData.gateWays[i]][_dashData[_dashData.gateWays[i]].listOfDevices[j]][_dashData[_dashData.gateWays[i]][_dashData[_dashData.gateWays[i]].listOfDevices[j]].listOfParams[k]]
            const holdAbit = `${_dashData.gateWays[i]}.${holdValue.page}.${holdValue.index}`
            objectFromServer_dash[holdAbit] = {displayName:holdValue.dashName,unit:holdValue.unit}
            objectFromServer_select[holdAbit] = true
            if(objOfLivePageIndex[_dashData.gateWays[i]]===undefined)objOfLivePageIndex[_dashData.gateWays[i]]=[]
            objOfLivePageIndex[_dashData.gateWays[i]].push([holdValue.page,holdValue.index])
          }
        }
        if(returnStruct===null)returnStruct ={}
        returnStruct[_dashData.gateWays[i]] = []

        let pageByteStruct ={}
        
        for(let k = 0;k<NUMBER_OF_PAGES;k++){ // run trhough all the possible pages since we cannot assume order
          const liveDataCount = objOfLivePageIndex[_dashData.gateWays[i]].length
          for(let j =0;j<liveDataCount;j++){
            if(objOfLivePageIndex[_dashData.gateWays[i]][j][0]===k){
              const pageValue = objOfLivePageIndex[_dashData.gateWays[i]][j][0] // the value indicating the page
              const byteValue = Math.floor((objOfLivePageIndex[_dashData.gateWays[i]][j][1]-1)/8) // the value indicating the byte number
              const byteValueAdd = Math.floor((objOfLivePageIndex[_dashData.gateWays[i]][j][1]-1)%8) // this should be processed and added to the with the other vlaues for the same byte 
              const propertyName = `${pageValue}.${byteValue}`
              
              if(pageByteStruct[propertyName]===undefined)pageByteStruct[propertyName] = {page:pageValue,byte:byteValue,byteValue:0}
              
              pageByteStruct[propertyName].byteValue += (Math.pow(2,byteValueAdd))
            }
          }
        }
        
        const setArray = Object.entries(pageByteStruct)
        const setCount = setArray.length

        for(let k =0;k<setCount;k++){
          //returnStruct[_dashData.gateWays[i]].push(`${Math.floor(setArray[k][1].page+1)}.${Math.floor(setArray[k][1].byte)}.${Math.floor(setArray[k][1].byteValue)}.${60}.`)
          returnStruct[_dashData.gateWays[i]].push(`${Math.floor(setArray[k][1].page+1)}.${Math.floor(setArray[k][1].byte)}.${255}.${120}.`) // todo make this something like 120 for now so that it will run for a min. change siver to refresh in 60s
        }
      }
    }catch(error){
      console.error(error)
    }
  }
  return {liveDataStrings:returnStruct,liveDataStructure:objectFromServer_dash}
}

function hexToFloat(hex) {
  const buffer = new ArrayBuffer(4);
  const view = new DataView(buffer);
  view.setUint32(0, parseInt(hex, 16), false);
  return view.getFloat32(0, false);
}

const getDriverDataFromServer = async (siteData,user,url)=>{
  const payLoad = siteData.gateways.map((item,index)=>(
    item.gatewayID_id
  ))

  try {
    const fetchDataJSON = {gateway_id: payLoad, page:-1,siteID:siteData.site_id}
    const response = await fetch(`${url}/api/sites/driver/${JSON.stringify(fetchDataJSON)}`, {
      method: 'GET',       
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      }
    });

    if (!response.ok) {
      const errorMessage = `Server error: ${response.status} - ${response.statusText}`;
      console.error(errorMessage);
      //setError(errorMessage);
      return null;
    }
    const holdResJson =  await response.json();
    return holdResJson
  } catch (error) {
    console.error('An error occurred:', error);
    //setError('An error occurred while communicating with the server');
  }
};