import { createContext, useReducer } from 'react';

export const SemiLiveDataContext = createContext();

export const SemiLiveDataReducer = (state = { SemiLiveData: null }, action) => {
  switch (action.type) {
    case 'LIVE_CHART':
      return {
        ...state,
        liveChart: action.payload,
      };
    case 'WS_STATUS':
      return {
        ...state,
        wsStatus: action.payload,
      };
    case 'VIEW_STATUS':
      return {
        ...state,
        viewStatus: action.payload,
      };
    case 'RESTART_WS':
      return{
        ...state,
        restartWS:action.payload,
      };
    default:
      return state;
  }
};

export const SemiLiveDataContextProvider = ({ children }) => {
  const [state, dispatchSemiLiveData] = useReducer(SemiLiveDataReducer, {
    SemiLiveData: null,
  });

  return (
    <SemiLiveDataContext.Provider value={{ ...state, dispatchSemiLiveData }}>
      {children}
    </SemiLiveDataContext.Provider>
  );
};
