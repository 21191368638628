import { useEffect }from 'react'
import { useGatewaysContext } from "../../hooks/useGatewaysContext"
import { useAuthContext } from "../../hooks/useAuthContext"

// components
import GatewayDetails from '../../components/gateway/GatewaysDetails'
import GatewayForm from '../../components/gateway/GatewayForm'
import { useNavContext } from '../../hooks/useNavContext'

const GatewayManage = () => {
  const {dispatchNav} = useNavContext()
  useEffect(()=>{
    dispatchNav({type:'NAV_INDEX',payload:'gatewayMan'})
  },[])
  const {gateways, dispatch} = useGatewaysContext()
  const {user} = useAuthContext()
  const rootUrl = window.location.origin; // Get the root URL dynamically

  useEffect(() => {
    const fetchGateways = async () => {
      const response = await fetch(`${rootUrl}/api/gateways`, {
        headers: {'Authorization': `Bearer ${user.token}`},
      })
      const json = await response.json()
      if (response.ok) {
        dispatch({type: 'SET_GATEWAYS', payload: json})
      }
    }

    if (user) {
      fetchGateways()
    }
  }, [dispatch, user])

  return (
    
    <div className="gateway">
      <div className="gateways">
        <div className='gateway-container'>
          {gateways && gateways.map((gateway) => (
            <GatewayDetails key={gateway._id} gateway={gateway} />
          ))}
        </div>
      </div>
      <GatewayForm />
    </div>
  )
}

export default GatewayManage