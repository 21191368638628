import React ,{useEffect}from 'react';
import ListOfSites from '../components/homePage/ListOfSites';
import { useSemiLiveDataContext } from '../hooks/useSemiLiveDataContext';
import { useNavContext } from '../hooks/useNavContext';
import {useStaticDataContext} from "../hooks/useStaticDataContext"
import { useUserContext } from '../hooks/useUserContext'

const HomePage = ({ argument: role }) => {
  const { dispatchUser } = useUserContext()
  const { dispatchStaticData,wsClass } = useStaticDataContext();
  const { dispatchNav } = useNavContext()
  useEffect(()=>{
    dispatchNav({type:'NAV_INDEX',payload:'Home'})
    if(wsClass?.timerID){clearInterval(wsClass?.timerID);console.log("Interval Cleared")}
    if(wsClass?.ws)wsClass?.ws.close()
    dispatchUser({type:'SET_SELECTED_SITE',payload:null})
  },[])
  
  switch (role) {
    case 'admin':
      return <>
          <ListOfSites parameter = {'chartData'}/>
      </>;
    case 'user':
      return (
        <>         
          <ListOfSites parameter = {'chartData'}/>
        </>
      );
    case 'client':
      return ( 
        <>         
          <ListOfSites parameter = {'chartData'}/>
        </>
      )
    default:
      return null;
  }
};

export default HomePage;
