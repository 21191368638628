import ConfigureDash from '../../components/site/ConfigureDash'

import ListOfSites from '../../components/homePage/ListOfSites';

const DashboardSiteSelect = () => {
    


    return(<div className="site">
      <ListOfSites parameter = {'dashboardConfig'}/>
  </div>
       
    )
}

export default DashboardSiteSelect