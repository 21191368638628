import { createContext, useReducer, useEffect } from 'react'

// Create a new context to share authentication state and dispatch function
export const AuthContext = createContext()

// Reducer function for managing the authentication state
export const authReducer = (state, action) => {
  switch (action.type) {
    case 'LOGIN':
      return { user: action.payload } // Set the user to the payload value
    case 'LOGOUT':
      return { user: null } // Set the user to null
    default:
      return state // Return the current state as is
  }
}

// Wrapper component that provides authentication state and dispatch function to its children
export const AuthContextProvider = ({ children }) => {
  // Use the authReducer to manage the state
  const [state, dispatch] = useReducer(authReducer, { 
    user: null // Initial state: no user is authenticated
  })

  // Side effect: Retrieve user data from local storage when the component mounts
  useEffect(() => {
    try{
      const user = JSON?.parse(localStorage.getItem('user'))
      if (user) {
        dispatch({ type: 'LOGIN', payload: user }) // Dispatch 'LOGIN' action with user data as payload
      }
    }catch(e){
      console.error(e)
    }
  }, [])

  // Log the current state of AuthContext to the console
   
  // Render the AuthContext.Provider, providing the state and dispatch function as the value
  return (
    <AuthContext.Provider value={{ ...state, dispatch }}>
      { children }
    </AuthContext.Provider>
  )
}
