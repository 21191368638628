import { useEffect }from 'react'
import { useSitesContext } from "../../hooks/useSitesContext"
import { useNavigate } from 'react-router-dom'

// components
import SiteDetails from '../../components/site/SiteDetails'
import SiteForm from '../../components/site/SiteForm'
import { useNavContext } from '../../hooks/useNavContext'

const SiteManage = () => {
  const navigate = useNavigate();
  const reloadPage = () => {
    window.location.reload(); // Reload the page
  };

  const {dispatchNav} = useNavContext()
  useEffect(()=>{
    dispatchNav({type:'NAV_INDEX',payload:'siteMan'})
  },[])
  const {sites, dispatch} = useSitesContext()
  return (
    
    <div className="site">
      <div className="sites">
        <div className='site-container'>
          {sites && sites.map((site) => (
            <SiteDetails key={site._id} site={site} />
          ))}
        </div>
      </div>
      <SiteForm />
    </div>
  )
}

export default SiteManage