import React, {useEffect,useRef,useState} from "react"
import { useUserSiteContext } from '../../hooks/useUserSite'
import { useSemiLiveDataContext } from '../../hooks/useSemiLiveDataContext'; 
import { useSitesContext } from "../../hooks/useSitesContext";
import { useUserContext } from "../../hooks/useUserContext";
import { useStaticDataContext } from "../../hooks/useStaticDataContext";
import DataChart from "../../components/site/DashboardChart";
import LiveDataTab from "../../components/site/LiveDataTabs";
import DisplayChart from "./components/DisplayChart";
import { LIVE_CHART_DAYS, LIVE_CHART_RES } from "../../constants";
import Right from '../../svg_components/arrows/right.svg'
import { useNavContext } from "../../hooks/useNavContext";
import { ComponentLoading } from "../../components/subcomp/InlineLoading";
import LiveDisplay from "./components/LiveDisplay";
import logoDesktop from '../../images/Energyneering_Clean.png'

const _ = require('lodash');
const echarts = require('echarts')

const DashPage = React.memo(() => {
  
  const roorURL = window.location.origin
  const { dispatch, gatewayParam } = useSitesContext();
  const { liveChart,wsStatus,viewStatus,dispatchSemiLiveData } = useSemiLiveDataContext();
  const { userSite, dispatchUserSite } = useUserSiteContext();
  const { activeSiteData } = useUserContext();
  const { isLanscape,wsClass } = useStaticDataContext()
  const { dispatchNav } = useNavContext()

  const [chartDataPass,setChartDataPass] = useState(null)
  const [chartDataView,setChartDataView] = useState(null)
  const [chartSelect,setChartSelect] = useState(null)
  const [chartDaySelect,setChartDaySelect] = useState();
  const [displayMainChart,setDisplayMainChart] = useState(false)
  const elementRef = useRef(null);
  


  function thisInfo(item,gateway,device,param){
    return {prop:`${gatewayParam[item.site_id]?.dashSettings[gateway][device][param].page}.${gatewayParam[item.site_id]?.dashSettings[gateway][device][param].index}`,id:`${gateway}.${device}.${param}`}
  }

  useEffect(() => {
    dispatchNav({type:'NAV_INDEX',payload:'dash'})
    const handleKeyDown = (event) => {
      if (event.key === 'Escape') {
        setDisplayMainChart(false);
      }
    };
    document.addEventListener('keydown', handleKeyDown);

    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, []);
  
  useEffect(() => {
    if (elementRef.current && elementRef.current.id) {
      try {
        let e = document.getElementById(elementRef.current.id);
        if (displayMainChart) {
          e?.requestFullscreen();
        } else if (document.fullscreenElement) {
          document.exitFullscreen();
        }
      } catch (e) {
        console.error(e);
      }
    }
  }, [displayMainChart]);

  useEffect(()=>{
    if(viewStatus&&!wsStatus&&(wsStatus!==undefined)){
      dispatchSemiLiveData({type:'RESTART_WS',payload:true}) // restart ws
    }
  },[wsStatus,viewStatus])

  useEffect(()=>{
    if(chartDataPass&&chartDaySelect){
      let holdChartDataPass = _.cloneDeep(chartDataPass)
      
      const keyArray = Object.keys(chartDataPass)
      const keyCount = keyArray.length
      for(let i = 0;i<keyCount;i++){
        const indexMax = (LIVE_CHART_DAYS)*(1440/LIVE_CHART_RES)-(chartDaySelect[keyArray[i]])*(1440/LIVE_CHART_RES)
        const indexMin = indexMax-(1440/LIVE_CHART_RES)
        let holdArray = [[]]
        for(let j =0;j<(1440/LIVE_CHART_RES);j++){
          holdArray.push(chartDataPass[keyArray[i]].data.data[j+indexMin])
        }
        const hold5 = holdArray
        holdChartDataPass[keyArray[i]].data.data = _.cloneDeep(hold5)
      }
      setChartDataView(holdChartDataPass)
    }
  },[chartDataPass,chartDaySelect])

  useEffect(()=>{
    if((!isLanscape)&&displayMainChart){
      setDisplayMainChart(false);
    }
  },[isLanscape,displayMainChart]);

 
  useEffect(()=>{
    if(liveChart){
      const allThechartValues = processChartDataForEcharts(liveChart,activeSiteData) // this is not a constant since we are changing the name before passing it to the hook
      
      const gatewayCount = activeSiteData?.gateways?.length
      let chartDataControl = {}
      let holdChartDaySelect = {}
      for(let i = 0;i<gatewayCount;i++){
        const gatewayID = activeSiteData.gateways[i].gatewayID
        if(allThechartValues[gatewayID]?.data){ //setChartDataPass(allThechartValues[activeSiteData.gateways[i].gatewayID][0])
          const paramCount = allThechartValues[gatewayID].data.length
          for(let j = 0;j<paramCount;j++){
            const holdNameArray = allThechartValues[gatewayID].data[j].name.split('.') // liveChart
            allThechartValues[gatewayID].data[j].name = liveChart[activeSiteData.siteID][gatewayID].displayInfo[`${gatewayID}.${holdNameArray[0]}.${holdNameArray[1]}`].dashName // changing the name of the chart in the chart options
            const holdID = `${gatewayID}.${holdNameArray[0]}.${holdNameArray[1]}`
            chartDataControl[holdID] = {data:allThechartValues[gatewayID]?.data[j],addSet:allThechartValues[gatewayID]?.addSet[j]}
            holdChartDaySelect[holdID] = 0
            if(chartSelect === null&&!j)setChartSelect(`${gatewayID}.${holdNameArray[0]}.${holdNameArray[1]}`) // set the initial chart
          }
        }
      }
      setChartDataPass(chartDataControl)
      setChartDaySelect(holdChartDaySelect)
    }
  },[liveChart])

  function selectChartFunction(selection){
    if(!isLanscape&&chartSelect){ // allow to deselect when it is not lanscape
      if(chartSelect===selection){
         setChartSelect(null)
        return
      }
    }   
    setChartSelect(selection)
  }

  function dayChange(tabID,flag){
    try{
      if(flag&&chartDaySelect[tabID]<(LIVE_CHART_DAYS-1)){
        let holdChartDaySelect = {...chartDaySelect}
        holdChartDaySelect[tabID] += 1
        setChartDaySelect(holdChartDaySelect)
      }else if((!flag)&&chartDaySelect[tabID]>0){
        let holdChartDaySelect = {...chartDaySelect}
        holdChartDaySelect[tabID] -= 1
        setChartDaySelect(holdChartDaySelect)
      }
    }catch(e){
      console.error(e)
    } 
  }
  
  return(
    <div className="site">
      <div className={isLanscape?'sitePage-flex':''} style={{width:'100vw'}}>    
        <div className={isLanscape?'liveData-container':'liveData_portrait-container'}>
          {activeSiteData&&userSite?.map((item, itemIndex) => ((item.site_id===activeSiteData.siteID)&&
          <ul key = {`sitesUl_${itemIndex}`}>
            <h2 style = {{alignContent:'center',width:'100%'}} key = {`siteH1_${itemIndex}`}>
            <button const onClick={()=>{isLanscape?setDisplayMainChart(true):alert("Only available in lanscape view")}} style = {{alignContent:'center',width:'90%',margin:'1rem'}} className="defaultButton">{item.siteName}</button>        
            </h2>
            <div key={`tableDiv_${itemIndex}`} className="liveData-holder">
              {gatewayParam&&gatewayParam[item.site_id]?.dashSettings?(gatewayParam[item.site_id]?.dashSettings.gateWays.map((gateway)=>( //
                gatewayParam[item.site_id]?.dashSettings[gateway].listOfDevices.map((device,deviceIndex)=>(
                  gatewayParam[item.site_id]?.dashSettings[gateway][device].listOfParams.map((param,paramIndex)=>(
                    <div key={`tabDiv_${itemIndex}${deviceIndex}${paramIndex}`} style={isLanscape?{}:{display:'flex',flexDirection: 'column',justifyContent:'center',width:'95vw'}} >
                      <li key = {`tabLi_${itemIndex}${deviceIndex}${paramIndex}`} style = {{border: (thisInfo(item,gateway,device,param).id===chartSelect)?'solid #00000033':'solid #9aaf9a'}} className="dashboard-button adCon" id = {thisInfo(item,gateway,device,param).id}>
                        {
                            <span  className={`right-span left-arrow ${(chartSelect===thisInfo(item,gateway,device,param).id)?'span-select':''}`} onClick={()=>dayChange(thisInfo(item,gateway,device,param).id,true)}>
                              <img className={`svg1 ${(chartSelect===thisInfo(item,gateway,device,param).id)?'svg1-active':''}`} src={Right}></img>
                            </span>
                          }
                          <div className="innerSpan-div adCon" onClick={(e)=>{selectChartFunction(thisInfo(item,gateway,device,param).id)}}>
                            <LiveDataTab key ={`tab_${itemIndex}${deviceIndex}${paramIndex}`} argument = {{gateway,prop:thisInfo(item,gateway,device,param).prop,id:thisInfo(item,gateway,device,param).id,item:item}}/>
                          </div>
                          {
                            <span  className={`right-span ${(chartSelect===thisInfo(item,gateway,device,param).id)?'span-select':''}`} onClick={()=>dayChange(thisInfo(item,gateway,device,param).id,false)}>
                              <img className={`svg1 ${(chartSelect===thisInfo(item,gateway,device,param).id)?'svg1-active':''}`} src={Right}></img>
                            </span>
                          }
                      </li>
                      <div className={`dash-chart-mobile ${(chartSelect===thisInfo(item,gateway,device,param).id)?'dash-chart-mobile-select':''}`}>
                        {(!isLanscape)&&(chartSelect===thisInfo(item,gateway,device,param).id)?<div id="dashDate"><h2>{getDashDate(chartDataView,chartSelect)}</h2></div>:<></>}
                        {((!isLanscape)&&(chartDataView?.[chartSelect]?.data?.data?.length<150)&&(chartSelect===thisInfo(item,gateway,device,param).id)&&chartSelect)?<DataChart argument = {{data:chartDataView[chartSelect].data,clean:true,chartSelect,siteID:activeSiteData.siteID,addSet:chartDataView[chartSelect]?.addSet}}/>:<></>}
                        </div>
                      </div>
                  ))
                ))
              ))):(<ComponentLoading/>)}
            </div>
          </ul>
          ))}
        </div>
        
        {isLanscape?<div className="dashPage-chart" style={{ width: '75vw', height: '85vh' }}>
          <div id="dashDate"><h2>{getDashDate(chartDataView,chartSelect)}</h2></div>
          {(isLanscape&&chartDataPass&&chartSelect)?<div style={{height:'100%'}}><DataChart argument = {{data:chartDataView?.[chartSelect]?.data,clean:false,chartSelect,siteID:activeSiteData.siteID,addSet:chartDataView?.[chartSelect]?.addSet}}/></div>:<ComponentLoading/>}
        </div>:<></>}
      </div>
      {
        <div className={`form-popup-bg ${displayMainChart?'is-visible':''}`} ref={elementRef} id="liveViewChart" style={{background:chartDataView?.[chartSelect]?.addSet?.bckColour||''}}>
          {activeSiteData?.siteID&&<div id="chartHeader">
            <div id="chartLogo"><img src={logoDesktop} className="chartLogo" /></div>
            <div id="displayChartTitle"><h2>{`${liveChart?.[activeSiteData.siteID]?.[chartSelect?.split('.')[0]]?.displayInfo[chartSelect]?.dashName||''}`}</h2></div>
            <dev id="liveChartData"><LiveDisplay argument = {{data:chartDataView?.[chartSelect]?.data,clean:false,chartSelect,siteID:activeSiteData.siteID,addSet:chartDataView?.[chartSelect]?.addSet}}/></dev>
            <button onClick = {()=>setDisplayMainChart(false)} className="close-button">Esc</button>

          </div>}
          <div className="background">
            {activeSiteData&&userSite?.map((item, itemIndex) => ((item?.site_id===activeSiteData?.siteID)&&item?.media?.chartBackGround?<img src={`${roorURL}/api/sites/displayimage/${item?.media?.chartBackGround}`}
              className="bckgImage"
            />:<></>))}
          </div>
          <div className="content">
            {activeSiteData?.siteID&&chartDataView?.[chartSelect]?.data&&<DisplayChart argument = {{data:chartDataView?.[chartSelect]?.data,clean:false,chartSelect,siteID:activeSiteData.siteID,addSet:chartDataView?.[chartSelect]?.addSet}}/>}
          </div>
        </div>
      }
    </div>   
  )
})

export default DashPage

// helper function:


function processChartDataForEcharts(_chartPass,_activeSiteData){
  const siteID = _activeSiteData?.siteID
  const arrayOfAllSiteGateway = _activeSiteData?.gateways

  const gatewayCount = arrayOfAllSiteGateway?.length
  const returnObj = {}
  if(gatewayCount){for(let i = 0;i<gatewayCount;i++){
    if(_chartPass?.[siteID]){
      const gatewayID = arrayOfAllSiteGateway[i]?.gatewayID
      if((_chartPass[siteID]?.[gatewayID])&&(_chartPass?.[siteID]?.[gatewayID]!==undefined)){
        if(_chartPass[siteID][gatewayID]?.data?.time){
          
          let argument = _chartPass[siteID][gatewayID]
          
          const beginDate = argument.timeInfo.timeRequest.from[gatewayID]
          const offsetErrorForChart = argument.timeInfo.timeRequest.currentDate.offset
            const parameterCount = argument?.arrayList?.length
            let seriesArrays = []
            let addSetArray = []
            let thisObj = {}
            let addSettings = {}
            for(let i =0;i<parameterCount;i++){
              try{
              if(argument?.data&&argument?.arrayList){ // Here we are using the array list [[<device>,<parameter>]]
                const _holdID = `${gatewayID}.${argument.arrayList[i][0]}.${argument.arrayList[i][1]}`
                const dispColour=_chartPass?.[siteID]?.[gatewayID].displayInfo?.[_holdID]?.colour
                const bckColour=_chartPass?.[siteID]?.[gatewayID].displayInfo?.[_holdID]?.bckColour
                if(argument.data[argument.arrayList[i][0]]){
                                     
                    if(argument.data[argument.arrayList[i][0]][argument.arrayList[i][1]].average){
                      const amendedData = fillMissingData(argument.data[argument.arrayList[i][0]][argument.arrayList[i][1]].average,argument.data.time,argument.timeInfo.minInterval,beginDate,offsetErrorForChart)
                      addSettings = {colour:dispColour,bckColour}
                      thisObj = {
                          name: `${argument.arrayList[i][0]}.${argument.arrayList[i][1]}.average`,
                          type: 'line',
                          smooth: 0.4,
                          symbol: 'none',
                          lineStyle: {
                            width: 2,
                            color: '#9aaf9a'
                          },
                          areaStyle: {
                            opacity: 0.8,
                            color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                              {
                                offset: 0,
                                color: '#9aaf9a88'
                              },
                              {
                                offset: 1,
                                color: '#ffffff33'
                              }
                            ])
                          },
                          tooltip:{
                            valueFormatter: value => value?value.toFixed(2):value
                          },                          
                          data: amendedData[0].map((item,index)=>([item,amendedData[1][index]]))//argument.data[argument.arrayList[i][0]][argument.arrayList[i][1]].average.map((item,index)=>([argument.data.time[index],item.toPrecision(4)])) 
                      }
                      seriesArrays.push(thisObj)
                      addSetArray.push(addSettings)
                    } 
                  } 
              }
              }catch(error){
                console.error(error)
              }
            }
            if(gatewayID)returnObj[gatewayID] = {data:seriesArrays,addSet:addSetArray}
          }
        }
    }
  }}
  return returnObj
}

function fillMissingData(dataArray, dateArray, step, beginDate,errorOffset) {
  const holdUnixTime = Math.round((new Date(beginDate).getTime())/1000)
  const fullTimeArrayCount = (1440*LIVE_CHART_DAYS)/(step>0?step:1)
  const secStep = step*60 // doing this before the loop saves computation
  let arrayCounter = 0
  let newDataArray = []
  let newTimeArray = []

  for(let i = 0;i<fullTimeArrayCount;i++){
    newTimeArray[i] = (holdUnixTime+secStep*i+errorOffset)
    if(newTimeArray[i]===dateArray[arrayCounter]){
      newDataArray[i] = dataArray[arrayCounter]
      arrayCounter++
    }else{
      newDataArray[i] = null
    }
  }
  return [newTimeArray.map((e)=>e*1000),newDataArray]//[newTimeArray,newDataArray];
}

const getDashDate =(data,select)=>{
  const epoch = data?.[select]?.data?.data?.[3]?.[0];
  const _date = epoch?new Date(epoch):null;
  const returnString = _date?.toLocaleDateString('en-US', { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' });//(_date?.getFullYear())?`${_date?.getFullYear()}-${(_date?.getMonth()+1)}-${_date?.getDate()}`:'';
  return returnString;
}