import React, { useEffect, useContext, useState, useRef } from 'react';
import { useUserSiteContext } from "../../hooks/useUserSite";
import { useAuthContext } from '../../hooks/useAuthContext';
import { ComponentLoading } from '../subcomp/InlineLoading';

function ConfigControlButton({gateways,siteInfo}) {
  const {user} =useAuthContext()
  const { userSite } = useUserSiteContext();
  const [inputPage, setInputPage] = useState(false);
  const [listOfInputs,setListOfInputs] = useState(null);
  const [inputData,setInputData] = useState()
  //////////////////////////////

  const getDataFromServer = async ()=>{

    const rootUrl = window.location.origin; 
    try {
      const fetchDataJSON = {siteID: siteInfo?._id}
      const response = await fetch(`${rootUrl}/api/sites/inputcontrol/${JSON.stringify(fetchDataJSON)}`, {
        method: 'GET',       
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      });
  
      if (!response.ok) {
        const errorMessage = `Server error: ${response?.status} - ${response?.statusText}`;
        console.error(errorMessage);
        //setError(errorMessage);
        return;
      }
  
      const holdJson = await response.json();
      handleServerData(holdJson)
      return holdJson;
    } catch (error) {
      console.error('An error occurred:', error);
      //setError('An error occurred while communicating with the server');
    }
  };

  function handleServerData(data){
    const holdObjArray = Object.entries(data)
    const numberOfInputs = holdObjArray?.length
    let holdListOfInputs = []
    for(let i = 0;i<numberOfInputs;i++){
      const type = data[holdObjArray[i][0]].inputType
      holdListOfInputs.push({inputID:data[holdObjArray[i][0]]?.id,inputName:data[holdObjArray[i][0]]?.label?.label,data:data[holdObjArray[i][0]]})
    }
    setListOfInputs(holdListOfInputs)
  }
  
  useEffect(()=>{
    getDataFromServer();
  },[])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === 'Escape') {
        // Handle the Escape key press here
        // Add your logic or function call here
        if (inputPage) {
          setInputPage(false);
        }
      }
    };

    // Add event listener when the component mounts
    document.addEventListener('keydown', handleKeyPress);

    // Remove event listener when the component unmounts
    return () => {
      document.removeEventListener('keydown', handleKeyPress);
    };
  }, [inputPage]); // Dependency on inputPage to update the effect when inputPage changes

  const handleAddInput = () => {
    let holdListOfInputs = null
    listOfInputs?holdListOfInputs=[...listOfInputs]:holdListOfInputs=[]
    if(holdListOfInputs.slice(-1)[0]?.inputID==='0000000_0000'){
        alert('Complete configuration of the previousely added button first')
        return null
    }
    holdListOfInputs.push({inputName:'New_Input',inputID:'0000000.0000'})
    setListOfInputs(holdListOfInputs)
    
    // Set focus to the document body when the modal is opened
    document.body.focus();
  };

  function deleteInput(e){
    let holdArray = []
    listOfInputs.map((item,index)=>{if(index!==e)holdArray.push(item)})
    setListOfInputs(holdArray)
  }

  function configInput(data){
    setInputData(data)
    setInputPage(true);
  }

  return (
    <>
      <div className='site'>
        <div className='site-container'>
          {listOfInputs
            ? listOfInputs.map((item, index) => (
                <React.Fragment key={index}>
                  <div className='manage-details'>
                    <h4>{item?.inputName}</h4>
                    <p>{item?.inputID}</p>
                    <div>
                      <button className = 'defaultButton' onClick={()=>configInput(item?.data)}>Configure Input</button>
                      <button className = 'defaultButton' onClick={() => deleteInput(index)}>
                        Delete Input
                      </button>
                    </div>
                  </div>
                </React.Fragment>
              ))
            : <ComponentLoading/>}
        </div>
      </div>
      <button className = 'defaultButton' onClick={handleAddInput} style={{ width:'16rem' }}>Add Input</button>
      <button className = 'defaultButton' style={{ width:'16rem' }}>Save Configuration</button>
      {inputPage ? (
        <InputPage_Toggle gateways = {gateways} siteInfo={siteInfo} user={user} inputPage={inputPage} setInputPage={setInputPage} inputData = {inputData} setInputData = {setInputData}/>
      ) : null}
    </>
  );
}

export default React.memo(ConfigControlButton);


function InputPage_Toggle({gateways,siteInfo,user,inputPage,setInputPage,inputData,setInputData}){
  const [activeValue,setActiveValue] = useState()
  const [inactiveValue,setInactiveVlaue] = useState()
  const [activeColour,setAtiveColour] = useState()
  const [inActiveColour,setIncativeColour] = useState()
  const [address,setAddress] = useState()
  const [activeText,setActiveText] =  useState()
  const [inactiveText,setInactiveText] = useState()
  
  const [label,setLabel] = useState('');
  const [info,setInfo] = useState(null);
  const [submitMessage,setSubmitMessage] = useState(null);
  const [authorisation,setAuthorisation] = useState('client');
  const [gateway] = useState(gateways)
  const [gatewaySelection,setGatewaySelection] = useState()
  const [formData,setFormData] = useState();
  
  const formPopupRef = useRef(null);
  
  const listOfSubmitMessageTypes = ['Warning','Info','Consideration']
  const listAuthorisation = ['developer','admin','user','client']

  useEffect(()=>{
    if(inputData){
      setAddress(inputData?.address)
      setActiveValue(inputData?.value[1])
      setInactiveVlaue(inputData?.value[0])
      setIncativeColour(inputData?.colour[0])
      setAtiveColour(inputData?.colour[1])
      setActiveText(inputData?.text[1])
      setInactiveText(inputData?.text[0])
      setSubmitMessage(inputData?.submitMessage)
      setLabel(inputData?.label)
      setInfo(inputData?.info)
      setAuthorisation(inputData?.auth)
      setGatewaySelection(inputData?.gateway)
    }
  },[inputData])

  function formAddress(e){
    const maxValue = 241;
    const minValue = 1;
    const addressValue = (e.value>maxValue)?(maxValue):(e.value)
    addressValue<minValue&&addressValue!==''?setAddress(minValue):setAddress(addressValue)
  }

  function setValueFunction(value,state){
    const maxValue = 65535
    const minValue = 0
    let Value = null
    switch(state){
      case 'state_1':{
        Value = value<maxValue?value:maxValue
        Value<minValue?setInactiveVlaue(minValue):setInactiveVlaue(Value)
      }break;
      case 'state_2':{
        Value = value<maxValue?value:maxValue
        Value<minValue?setActiveValue(minValue):setActiveValue(Value)
      }break;
    }
  }

  function submitData(){
    if(!label||!authorisation||!address||!inactiveValue||!activeValue||!inActiveColour||!activeColour||!activeText||!inactiveText||!gatewaySelection){
      alert('Please complete the entire form')
    }else{
      _submitData({inputType:'Toggle',label:label,info:info,submitMessage:submitMessage,auth:authorisation,gateway:gatewaySelection,address:address,value:[inactiveValue,activeValue],colour:[inActiveColour,activeColour],text:[inactiveText,activeText]})
    }
  }

  function formSubmitMessage(e, type) {
    let holdSubMessage = { ...submitMessage };
  
    switch (type) {
      case 'string':
        holdSubMessage.string = e.value;
        break;
      case 'flag':
        holdSubMessage.flag = e.checked;
        break;
      case 'select':
        holdSubMessage.select = e.value;
        break;
      case 'type':
        holdSubMessage.type = e.value;
        break;
      default:
        break;
    }
  
    setSubmitMessage(holdSubMessage);
  }

  function formLabel(e){
    let holdLabel = {...label}
    holdLabel.label = e.value
    setLabel(holdLabel)
  }

  function formInfo(e, type) {
    let holdInfo = { ...info };
  
    switch (type) {
      case 'string':
        holdInfo.string = e.value;
        break;
      case 'flag':
        holdInfo.flag = e.checked;
        break;
      default:
        break;
    }
  
    setInfo(holdInfo);
  }

  async function _submitData(storeData){
    const rootURL = window.location.origin
      
    const holdData = {
      ...storeData,
      id: `${storeData?.gateway}_${(storeData?.address).toString(16)}00`
    };

    const sendData = {data:holdData,id:siteInfo?._id}
      
      try {
        const response = await fetch(`${rootURL}/api/sites/inputcontrol`, {
          method: 'POST',
          body: JSON.stringify(sendData),
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user?.token}`
          }
        });
    
        if (!response.ok) {
          const errorMessage = `Server error: ${response?.status} - ${response?.statusText}`;
          console.error(errorMessage);
          //setError(errorMessage);
          return;
        }
        // If the response is successful, you can extract and display the response message
      } catch (error) {
        console.error('An error occurred:', error);
       
      }
  
  }

  return(
    <div className="form-popup-bg is-visible" ref={formPopupRef}>
          <button className = 'defaultButton' onClick={() => setInputPage(false)} style={{ position: 'absolute', top: 0, right: '0.3rem' }}>
            Esc
          </button>
          <div className='form-container'>
          <div style = {{overflow:'auto'}}>
            <table style={{ borderCollapse: 'collapse' }}>
              <tbody>
                <tr>
                  <td>Input Type:</td>
                  <td>Toggle</td>
                  <td></td>
                </tr>
                <tr>
                  <td>Label:</td>
                  <td><input type='text' onChange={(event)=>formLabel(event.target)} value = {label?.label} style={{ width: '12rem' }} /></td>
                  <td></td>
                  <td><input checked={true} disabled = {true} type='checkbox'></input></td>
                </tr>
                <tr>
                  <td>Info:</td>
                  <td><input onChange = {(event)=>formInfo(event.target,'string')} value = {info?.string} type='text' style={{ width: '12rem' }} /></td>
                  <td></td>
                  <td><input onChange={(event)=>formInfo(event.target,'flag')} checked = {info?.flag} type='checkbox'></input></td>
                </tr>
                <tr>
                  <td>Submit Message:</td>
                  <td><input value={submitMessage?.string} onChange = {(event)=>formSubmitMessage(event.target,'string')} type='text' style={{ width: '12rem' }} /></td>
                  <td><select value = {submitMessage?.select} onChange={(event)=>formSubmitMessage(event.target,'select')}>{listOfSubmitMessageTypes.map((item, index) => (<option key={index}>{item}</option>))}</select></td>
                  <td><input  checked = {submitMessage?.flag} onChange = {(event)=>formSubmitMessage(event.target,'flag')} type='checkbox'></input></td>
                </tr>
                <tr>
                  <td>Authorisation:</td>
                  <td><select value = {authorisation} onChange={(event)=>setAuthorisation(event.target.value)} style={{ width: '12rem' }}>{listAuthorisation.map((item, index) => (<option vlaue = {submitMessage?.type} onChange = {(event)=>formSubmitMessage(event.target,'type')} key={index}>{item}</option>))}</select></td>
                  <td></td>
                  <td><input checked={true} disabled = {true} type='checkbox'></input></td>
                </tr>
                <tr>
                  <td>Gateway</td>
                  <td><select value={gatewaySelection} onChange = {(event)=>setGatewaySelection(event.target.value)}>{gateway.map((item,index)=>(<option key = {`gatewayOption${index}`}>{item?.gatewayID}</option>))}</select></td>
                  <td></td>
                  <td></td>
                </tr>
              </tbody>
            </table>
            <table>
            <tbody>
              <td>Address</td>
              <td><input type='number' max={241} min={1} value={address} onChange={(event)=>formAddress(event.target)}></input></td>
            </tbody>
            <tbody>
              <tr>
                <td></td>
                <td>State 1</td>
                <td>State 2</td>
              </tr>
              <tr>
                <td>
                  Value
                </td>
                <td><input type='number' value = {inactiveValue} onChange={(event)=>setValueFunction(event.target.value,'state_1')}></input></td>
                <td><input type='number' value={activeValue} onChange={(event)=>setValueFunction(event.target.value,'state_2')}></input></td>
              </tr>
              <tr>
                <td>Colour</td>
                <td><input type='color' style={{background:`${inActiveColour}`}} onChange = {(event)=>setIncativeColour(event.target.value)}></input></td>
                <td><input type='color' style={{background:`${activeColour}`}} onChange = {(event)=>setAtiveColour(event.target.value)}></input></td>
              </tr>
              <tr>
                <td>Text</td>
                <td><input type='text' value={inactiveText} onChange={(event)=>setInactiveText(event.target.value)}></input></td>
                <td><input type='text' value={activeText} onChange={(event)=>setActiveText(event.target.value)}></input></td>
              </tr>
            </tbody>
          </table>
          </div>
          <button className = 'defaultButton' onClick={submitData} style = {{width:'6rem'}}>Submit</button>
        </div>
        </div>
  )
}