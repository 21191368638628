import React from "react";
import { useLiveDataContext } from "../../hooks/useLiveDataContext";
import { useSemiLiveDataContext } from "../../hooks/useSemiLiveDataContext";
import { useUserContext } from "../../hooks/useUserContext";

import "./LiveDataTab.css"; // Import CSS file for styling
import {InlineLoading} from "../subcomp/InlineLoading";
import { unitConverter } from "../functionalComponents/unitConverter";

export default function LiveDataTab({ argument }) {
  const { liveData, wsOpen } = useLiveDataContext();
  const { liveChart } = useSemiLiveDataContext();
  const { userRole } = useUserContext()
  const liveDataValue = liveData?.[argument.gateway]?.[argument.prop];
  const liveChartData = liveChart?.[argument.item.site_id]?.[argument.gateway]?.displayInfo[argument.id]?.dashName;

  return (
    <>
      {liveDataValue ? (
        <span className={dataExpire(liveDataValue.time) ? "expired" : ""}>
          {handlingLiveData(liveDataValue,userRole,wsOpen).live}
          <i className="adInfo">{handlingLiveData(liveDataValue,userRole,wsOpen).admin}</i>
        </span>
      ) : (
        <span className={liveChartData ? (dataExpire(liveChartData.time) ? "expired" : "") : ""}>
          {liveChartData ? liveChartData : <InlineLoading/>}
        </span>
      )}
    </>
  );

}

function handlingLiveData(_liveData,userRole,wsOpen) {
  if (!_liveData) return { live: '', load: '' };
  const _data = unitConverter(_liveData.liveData,_liveData?.unit)
  return {
    live: `${_liveData?.displayName}${_liveData?.displayName !== '' ? ':' : ''} ${_data.value.toFixed(2)} ${_data.unit}`,
    admin:`${userRole==='admin'?((Date.now()-_liveData.time)/1000).toFixed(2):''} ${userRole==='admin'?(wsOpen?'open':'close'):''}`,
    load: `${_liveData?.displayName} Loading... ${_liveData.unit}`
  };
}

function dataExpire(dataTime) {
  const currentTime = Date.now();
  return dataTime + 10000 < currentTime;
}
