import React, { useState, useEffect } from 'react'
import { useGatewaysContext } from '../../hooks/useGatewaysContext';
import { useAuthContext } from '../../hooks/useAuthContext';



const LocationForm = ({gateway})=>{
    const { dispatch } = useGatewaysContext();
    const [gatewayLocation,setGatewayLocation] = useState({lat:0,long:0});
    const { user } = useAuthContext()
  const [error, setError] = useState(null)
    
    const handleSubmit = async (e) => {
    
          const rootUrl = window.location.origin; // Get the root URL dynamically
          const holdObj = {gatewayID: gateway?.gatewayID, data:gatewayLocation }
    
          try {
            const response = await fetch(`${rootUrl}/api/gateways/location`, {
              method: 'POST',
              body: JSON.stringify(holdObj),
              headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${user.token}`
              }
            });
        
            if (!response.ok) {
              const errorMessage = `Server error: ${response?.status} - ${response?.statusText}`;
              console.error(errorMessage);
              setError(errorMessage);
              return;
            }
            // If the response is successful, you can extract and display the response message
            const responseData = await response.json();
            alert(responseData?.message)
            closeForm()
          } catch (error) {
            console.error('An error occurred:', error);
            setError('An error occurred while communicating with the server');
          }
          
        
    };

    const getDataFromServer = async ()=>{

      const rootUrl = window.location.origin; 
      try {
        const fetchDataJSON = {gatewayID: gateway?.gatewayID}
        const response = await fetch(`${rootUrl}/api/gateways/location/${JSON.stringify(fetchDataJSON)}`, {
          method: 'GET',       
          headers: {
            'Content-Type': 'application/json',
            'Authorization': `Bearer ${user.token}`
          }
        });
    
        if (!response.ok) {
          const errorMessage = `Server error: ${response?.status} - ${response?.statusText}`;
          console.error(errorMessage);
          setError(errorMessage);
          return;
        }
    
        const holdJson = await response.json();
        setGatewayLocation(holdJson)
        return holdJson;
      } catch (error) {
        console.error('An error occurred:', error);
        setError('An error occurred while communicating with the server');
      }
    };
    
      useEffect(()=>{
        getDataFromServer();
      },[])

    const closeForm = ()=>{
        dispatch({ type: 'SET_LOCATION_FORM_FLAG', payload: null });
    }

    const handleOnChange = (e)=>{
        let holdLocation = gatewayLocation;
        holdLocation.lat = parseFloat(e.target.value.split(',')[0],10);
        holdLocation.long = parseFloat(e.target.value.split(',')[1],10);
        setGatewayLocation(holdLocation);
    }    

    return (
        <div className="form-popup-bg is-visible">      
        <div className="form-container">
        <button className = 'defaultButton' onClick={closeForm}>X</button>
        <h1>{"Set location for: "+gateway?.gatewayID}</h1>
        <div id="formScrollContainer">      
            <table>
                <tbody>
                    <tr>
                    <td>
                        <input type='text' placeholder= {gatewayLocation?.lat?`${gatewayLocation?.lat},${gatewayLocation?.long}`:'Latitude,Longitude'}  onChange={(e)=>handleOnChange(e)}></input>
                    </td>
                    
                    </tr>
                </tbody>
            </table>       
        </div>
            <button className = 'defaultButton' type="button" onClick={handleSubmit}>Set Location</button>
          <pre id="jsonOutput"></pre>
        </div>    
        </div>
      );
}

export default LocationForm;