import { createContext, useReducer } from 'react'

export const GatewaysContext = createContext()

export const gatewaysReducer = (state, action) => {
  switch (action.type) {
    case 'SET_GATEWAYS': 
      return {
        ...state,
        gateways: action.payload
      }
    case 'CREATE_GATEWAY':
      return {
        ...state,
        gateways: [action.payload, ...state.gateways]
      }
    case 'DELETE_GATEWAY':
      return {
        ...state,
        gateways: state.gateways.filter((w) => w._id !== action.payload._id)
      }
    case 'SET_FORM_FLAG':
      return{
        ...state,
        formFlag: action.payload
      }
    case 'SET_FORMOPEN_FLAG':
      return{
        ...state,
        fromOpenFlag: action.payload
      }
    case 'SET_LOCATION_FORM_FLAG':
      return{
        ...state,
        formLocationFlag: action.payload
      }
    default:
      return state
  }
}

export const GatewaysContextProvider = ({ children }) => {
  const [state, dispatch] = useReducer(gatewaysReducer, {
    gateways: null,
    formFlag: false // Add formFlag to the initial state
  })

  return (
    <GatewaysContext.Provider value={{ ...state, dispatch }}>
      {children}
    </GatewaysContext.Provider>
  )
}
