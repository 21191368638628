import { createContext, useReducer } from 'react';

export const LiveDataContext = createContext();

export const liveDataReducer = (state = { liveData: null }, action) => {
  switch (action.type) {
    case 'DASH_DATA': 
      return {
        ...state,
        liveData: action.payload,
      };
    case 'WS_STATUS': 
      return {
        ...state,
        wsOpen: action.payload,
      };   
    default:
      return state;
  }
};

export const LiveDataContextProvider = ({ children }) => {
  const [state, dispatchLiveData] = useReducer(liveDataReducer, {
    liveData: null,
  });

  return (
    <LiveDataContext.Provider value={{ ...state, dispatchLiveData }}>
      {children}
    </LiveDataContext.Provider>
  );
};
