import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom'
import { useAuthContext } from './hooks/useAuthContext'
import React,{useEffect,useState} from 'react'
import jwtDecode from 'jwt-decode'

// pages & components
import SiteManage from './pages/site/SiteManage'
import UserManage from './pages/user/UserManage'
import GatewayManage from './pages/gateway/GatewayManage';
import Login from './pages/Login';
import DashPage from './pages/site/DashPage';
import DashboardSiteSelect from './pages/site/DashboardSiteSelect';
import ConfigureDash from './components/site/ConfigureDash';
import DataExplore from './pages/site/DataExplore';
import DataExplore_page from './components/site/DataExplore';
import InfoBanner from './components/InfoBanner';
import NavBar from './components/NavBar';
import HomePage from './pages/HomePage';
import CinfigureControlInputSiteSelect from './components/site/ConfigureControlInputSiteSelect';
import Control from './components/site/Control';

import { LIVE_CHART_DAYS,LIVE_CHART_RES } from './constants'
import { useLiveDataContext } from './hooks/useLiveDataContext';
import { useSemiLiveDataContext } from './hooks/useSemiLiveDataContext';
import { useUserSiteContext } from './hooks/useUserSite';
import { useSitesContext } from './hooks/useSitesContext';
import { useStaticDataContext} from './hooks/useStaticDataContext';
import { useUserContext } from './hooks/useUserContext';
import { useNavContext } from './hooks/useNavContext';

const {startWS} = require('./components/functionalComponents/manageWS');

function App() {
  const { dispatchNav, sideBarState } = useNavContext()
  const {activeSiteData,userRole,dispatchUser} = useUserContext()
  const { dispatch, gatewayParam,sites } = useSitesContext();
  const { wsClass,timeOffset,timeZoneErr,dispatchStaticData,isLanscape } = useStaticDataContext();
  const { dispatchSemiLiveData,wsStatus,restartWS } = useSemiLiveDataContext()
  const { liveChart,liveData, dispatchLiveData,viewStatus } = useLiveDataContext();
  const { userSite, dispatchUserSite } = useUserSiteContext();
  const { user } = useAuthContext()
  const [loading, setLoading] = useState(true); // Add loading state
  const [error, setError] = useState(null);
  const [settingData, setSettingData] = useState(false);

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.visibilityState === "visible") {
        dispatchSemiLiveData({type:'VIEW_STATUS',payload:true})
      } else {
        dispatchSemiLiveData({type:'VIEW_STATUS',payload:false})
      }
    };
  
    document.addEventListener("visibilitychange", handleVisibilityChange);
  
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    const handleResize = () => {
      const screenWidth = window.innerWidth;
      const screenHeight = window.innerHeight;

      dispatchStaticData({type:'SCREEN_STATE',payload:screenWidth > screenHeight})
    };

    // Initial check
    handleResize();

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Remove event listener on component unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(()=>{
    if(user&&userSite&&activeSiteData&&sites&&(!settingData)){
      let holdUserSiteArray = []
      userSite.map((item,index)=>{if(item?.site_id===activeSiteData.siteID)holdUserSiteArray.push(item)}) // this was added to limit it to the selected site
      getGatewayParams(holdUserSiteArray)
      let _id = null
      sites.map((item,index)=>{if(item.siteID===activeSiteData.siteID)_id=item._id})
      if(_id){
        getInfoDataFromServer(_id)
        setSettingData(true)
      }
      wsFunction(user,holdUserSiteArray,dispatchLiveData,liveData)
    }
  },[user,userSite,activeSiteData])

  useEffect(()=>{
    if(restartWS){
      let holdUserSiteArray = []
      userSite.map((item,index)=>{if(item?.site_id===activeSiteData.siteID)holdUserSiteArray.push(item)})
      wsFunction(user,holdUserSiteArray,dispatchLiveData,liveData)
      clearInterval(wsClass?.timerID)
      dispatchSemiLiveData({type:'RESTART_WS',payload:false})
    }
  },[restartWS])

  async function wsFunction(user,holdUserSiteArray,dispatchLiveData,liveData){
    const holdWS_info = await startWS(user,holdUserSiteArray,dispatchLiveData,liveData);
    dispatchSemiLiveData({type:'WS_STATUS',payload:true})
    holdWS_info.ws.addEventListener('close',()=>{
      dispatchSemiLiveData({type:'WS_STATUS',payload:false})
    })
    dispatchStaticData({type:'WS_CLASS',payload:holdWS_info})
  }

  
  useEffect(()=>{
    if(settingData){
      setSettingData(false)
    }
  },[activeSiteData])

  const getInfoDataFromServer = async (_id)=>{

    const rootUrl = window.location.origin; 
    try {
      const fetchDataJSON = {siteID: _id}
      const response = await fetch(`${rootUrl}/api/sites/inputcontrol/${JSON.stringify(fetchDataJSON)}`, {
        method: 'GET',       
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      });
  
      if (!response.ok) {
        const errorMessage = `Server error: ${response.status} - ${response.statusText}`;
        console.error(errorMessage);
        //setError(errorMessage);
        return;
      }
  
      const holdJson = await response.json();
      let _hold = {...activeSiteData,input:holdJson}
      dispatchUser({type:'SET_SELECTED_SITE',payload:_hold})
      //handleServerData(holdJson)
      return holdJson;
    } catch (error) {
      console.error('An error occurred:', error);
      //setError('An error occurred while communicating with the server');
    }
  };

  useEffect(() => {
    const fetchSites = async () => {
      const rootUrl = window.location.origin; // Get the root URL dynamically
      const response = await fetch(`${rootUrl}/api/sites`, {
        method:'GET',
        headers: {'Authorization': `Bearer ${user.token}`},
      })
      const json = await response?.json()
      if (response.ok) {
        dispatch({type: 'SET_SITES', payload: json})
      }
    }

    if (user) {
      fetchSites()
    }
  }, [user])

  useEffect(()=>{
      if(gatewayParam){
        _chartData()
        const intervalId = setInterval(() => {
          if(gatewayParam)_chartData()
        }, 300000); // updates the data every 5 min to get the 10 min data
        
      }
  },[gatewayParam])

  async function _chartData(){
    try{

    if(gatewayParam){ // this function fetch and store the chart data in the hook for live data
      let holdChartData = {} // for all the sites live data charts
      let holdUserSiteArray = []
      userSite.map((item,index)=>{if(item?.site_id===activeSiteData.siteID)holdUserSiteArray.push(item)})
      const siteCount =  holdUserSiteArray.length
      for(let i = 0;i<siteCount;i++){ // we are saving the data according to the sites
        const siteID = holdUserSiteArray[i]?.site_id // site id
        holdChartData[siteID] = {}
        const gatewayCount = (gatewayParam?.[siteID]?.dashSettings?.gateWays)?gatewayParam?.[siteID].dashSettings.gateWays.length:0 // gatewayParam?.[siteID] is undefined
        for(let j=0;j<gatewayCount;j++){
          const gatewayID = gatewayParam?.[siteID].dashSettings.gateWays[j] // gatewayID
          if(gatewayParam?.[siteID].payLoad,gatewayParam?.[siteID].payLoad[gatewayID]){
            let holdDisplayInfo = {}
            let holdGateWayReqData = []
            const deviceCount = gatewayParam?.[siteID]?.payLoad?.[gatewayID]?.[0]?.length; 
            const arrayOfDashDevices = gatewayParam?.[siteID].dashSettings[gatewayID].listOfDevices
            for(let k = 0;k<deviceCount;k++){
              
              const pageCount = gatewayParam?.[siteID].payLoad[gatewayID].length
              
              for(let m = 0;m<pageCount;m++){ // to do: we are having trouble with the pages if one page is skipped
                if(gatewayParam?.[siteID].payLoad[gatewayID][m]){
                  const deviceCount = gatewayParam?.[siteID].payLoad[gatewayID]?.[m]?.length
                  for(let n = 0;n<deviceCount;n++){
                    const page = m
                    const deviceName = gatewayParam?.[siteID].payLoad[gatewayID][m][n].deviceName
                    const paramCount = gatewayParam?.[siteID].payLoad[gatewayID][m][n].parameters.length
                    if (arrayOfDashDevices.includes(deviceName)) {
                      const arrayOfDashParameters = gatewayParam?.[siteID].dashSettings[gatewayID][deviceName].listOfParams; // this contains the dash settings such as the unit and names
                      for (let p = 0; p < paramCount; p++) {
                        const parameterName = gatewayParam?.[siteID].payLoad[gatewayID][m][n].parameters[p].parameterName;
                        if (arrayOfDashParameters.includes(parameterName)) {
                          const paramDisplayName = gatewayParam?.[siteID].dashSettings[gatewayID][deviceName][parameterName].dashName
                          const paramDisplayUnit = gatewayParam?.[siteID].dashSettings[gatewayID][deviceName][parameterName].unit
                          const existingEntry = holdGateWayReqData.find(entry => entry[0] === deviceName && entry[1] === parameterName);
                          if (!existingEntry) {
                            holdGateWayReqData.push([deviceName, parameterName]);
                            holdDisplayInfo[`${gatewayID}.${deviceName}.${parameterName}`] = {dashName: paramDisplayName,dashUnit:paramDisplayUnit,colour: gatewayParam?.[siteID].dashSettings[gatewayID][deviceName][parameterName]?.displayColour,bckColour: gatewayParam?.[siteID].dashSettings[gatewayID][deviceName][parameterName]?.bckColour}
                          }
                        }
                      }
                    }
                  }
                }
              }
                
            }
            
            let holdData = {}
            holdData[gatewayID] = holdGateWayReqData
            const time_structured = await getTheTime(gatewayParam?.[siteID]?.location[gatewayID],gatewayParam?.[siteID]?.location[gatewayID]?.tzid,gatewayID)
              if(time_structured){
                const thisObj = {gateways:[gatewayID],data:holdData,time: {from:time_structured.from,to:time_structured.to},siteID:siteID,res:LIVE_CHART_RES,max:false,min:false,avg:true} 
                const serverData = await getLoggedData(thisObj,user)
                if(serverData?.data?.[gatewayID])holdChartData[siteID][gatewayID] = {gateway:gatewayID,arrayList:holdGateWayReqData,data:serverData.data[gatewayID],chartType:{max:false,min:false,avg:true},displayInfo:holdDisplayInfo,timeInfo:{minInterval:10,timeRequest:time_structured}}
              }else{
                holdChartData[siteID][gatewayID] = null
              }
            }
        }
      }      
      dispatchSemiLiveData({type:'LIVE_CHART',payload:holdChartData}) 
    }
    }catch(e){
      console.error("Errors in _chartData",e)
    }
  }

  
  const getLoggedData = async (getData,user)=>{
    try {
      const rootUrl = window.location.origin;
      const response = await fetch(`${rootUrl}/api/gateways/data/${JSON.stringify(getData)}`, {
        method: 'GET',       
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      });
      
      if (!response.ok) {
        const errorMessage = `Server error: ${response.status} - ${response.statusText}`;
        console.error(errorMessage);
        setError(errorMessage);
        return null;
      }
      const holdResJson =  await response.json();
      return holdResJson
    } catch (error) {
      console.error('An error occurred:', error);
      setError('An error occurred while communicating with the server');
    }
  };

  async function getTheTime(_timeInfo,tzid,gatewayID){
    let holdReturnJson = null
    holdReturnJson = await getTheTimeFromTheServer()
    if(holdReturnJson&&holdReturnJson?.time&&holdReturnJson?.time!==null){

      function getOffset(timeZone){ // only place used, so place locally.
        const timeZoneName = Intl.DateTimeFormat("ia", {
          timeZoneName: "shortOffset",
          timeZone,
        })
          .formatToParts()
          .find((i) => i.type === "timeZoneName").value;
        const offset = timeZoneName.slice(3);
        if (!offset) return 0;
      
        const matchData = offset.match(/([+-])(\d+)(?::(\d+))?/);
        if (!matchData) throw `cannot parse timezone name: ${timeZoneName}`;
      
        const [, sign, hour, minute] = matchData;
        let result = parseInt(hour) * 60;
        if (sign === "+") result *= -1;
        if (minute) result += parseInt(minute);
      
        return result;
      };

      const serverTimeOffsetMinute = getOffset(tzid)
      const holdDate = new Date(holdReturnJson.time)
      const systemTimeOffset = holdDate.getTimezoneOffset()
      const timeOffsetError = serverTimeOffsetMinute-systemTimeOffset
      if(timeZoneErr!==timeOffsetError){ // the error is own to the browser and is there to fix the eCharts so it is not needed for the error to be gateway specific
        dispatchStaticData({type:'TIME_ZONE_ERR',payload:timeOffsetError})
      }
      if(timeOffset!==serverTimeOffsetMinute){
        dispatchStaticData({type:'TIME_ZONE_OFFSET',payload:serverTimeOffsetMinute*60})
      }
      const firstHourOfDay = addSecondsToIsoTime((new Date(holdDate.getFullYear(),holdDate.getMonth(),holdDate.getDate(),0,(0),0,0-(LIVE_CHART_DAYS-1)*86400000).toISOString()),timeOffsetError*60)
      const lastHourOfDay = addSecondsToIsoTime(new Date(holdDate.getFullYear(),holdDate.getMonth(),holdDate.getDate(),23,(59),59,999).toISOString(),timeOffsetError*60)    
      let firstObj = {}
      let lastObj = {}
      firstObj[gatewayID] = firstHourOfDay
      lastObj[gatewayID] = lastHourOfDay
      return {from:firstObj,to:lastObj,currentDate:{date:holdReturnJson.time,offset:timeOffsetError}}
    }else{
      return null
    }
  }

  async function getTheTimeFromTheServer(){
    const rootUrl = window.location.origin;
    try {
      const response = await fetch(`${rootUrl}/api/info/time`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      });

      if (!response.ok) {
        const errorMessage = `Server error: ${response.status} - ${response.statusText}`;
        console.error(errorMessage);
        setError(errorMessage);
        return null
      }
      const holdReturnJson =  await response.json();
      return holdReturnJson
      
    } catch (error) {
      console.error('An error occurred:', error);
      setError('An error occurred while communicating with the server');
      return null
    }
  }

  async function getGatewayParams(_siteData){
    const siteCount = _siteData.length
    let holdSiteGatewayData = {}
    for(let i =0;i<siteCount;i++){
      const siteID = _siteData[i].site_id
      const siteName = _siteData[i].siteName
      const gatewayArray = _siteData[i].gateways.map((item)=>(item.gatewayID_id))
      holdSiteGatewayData[siteID] = await getDriverDataFromServer(gatewayArray,siteID)
    }
    
    dispatch({type:'SET_GW_PARAM',payload:holdSiteGatewayData})
  }

  const getDataFromServer = async () => {
    const rootUrl = window.location.origin;
    try {
      const response = await fetch(`${rootUrl}/api/user/site/${user._id}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      });

      if (!response.ok) {
        const errorMessage = `Server error: ${response.status} - ${response.statusText}`;
        console.error(errorMessage);
        setError(errorMessage);
        return;
      }
      const holdJsonString = await response.json()
      return holdJsonString;
      
    } catch (error) {
      console.error('An error occurred:', error);
      setError('An error occurred while communicating with the server');
    }
  };

  const getDriverDataFromServer = async (gateWaySiteID_array,siteID)=>{
    
    const rootUrl = window.location.origin; 
    try {
      const fetchDataJSON = {gateway_id: gateWaySiteID_array, page:-1,siteID:siteID}
      const response = await fetch(`${rootUrl}/api/sites/driver/${JSON.stringify(fetchDataJSON)}`, {
        method: 'GET',       
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      });
  
      if (!response.ok) {
        const errorMessage = `Server error: ${response.status} - ${response.statusText}`;
        console.error(errorMessage);
        setError(errorMessage);
        return;
      }
      const holdResJson =  await response.json();
      dispatch({type:'SET_GW_PARAM',payload:holdResJson}) // todo: see if this should be commented out
      return holdResJson
    } catch (error) {
      console.error('An error occurred:', error);
      setError('An error occurred while communicating with the server');
    }
  };

  const fetchListOfSites = async () => {
    const siteList = await getDataFromServer();
    const holdTheSiteList = siteList?.map(item => ({ site_id: item?.siteID,siteName:item?.siteName, gateways:item?.gateways,media: item?.media}));
    dispatchUserSite({type: 'USER_SITES',payload: holdTheSiteList});
    setLoading(false); // Set loading to false when data is fetched
  };

  useEffect(() => {
    if(user&&sites){
      fetchListOfSites()
      dispatchUser({type:'SET_USER_ROLE',payload:jwtDecode(user.token).role})
    }

  }, [user,sites]);


  return (
    <div className="App">
        {<InfoBanner argument={userRole} />}
          <div className="pages">
          {<div onClick={()=>dispatchNav({type:'SIDEBAR_STATE',payload:false})} className={`sidebar-overlay fade ${sideBarState?'is-shown':''}`}></div>}
          {(userRole==='admin'||userRole==='user'||userRole==='client')?<div className='sidebar-container'>
            <div className={`sidebar ${sideBarState?isLanscape?'show':'show-mobile':''}`}>
              <NavBar/>
              <div className='nav-filler'></div>
            </div>
            {user&&<div className={`sidebar-tab ${sideBarState?'hide':''}`} onClick={()=>dispatchNav({type:'SIDEBAR_STATE',payload:sideBarState?false:true})}></div>}
          </div>:<></>}
          <Routes>
            <Route 
              path="/" 
              element={user ? <HomePage argument={userRole} /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/gatewaymanage" 
              element={user ? <GatewayManage /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/configcontrolinputselect" 
              element={user ? <CinfigureControlInputSiteSelect /> : <Navigate to="/login" />}
            />

            <Route 
              path="/sitecontrol" 
              element={user ? <Control /> : <Navigate to="/login" />}
            />

            <Route 
              path="/dashboardsiteselect" 
              element={user ? <DashboardSiteSelect /> : <Navigate to="/login" />} //dashboardmanage
            />
            <Route 
              path="/dashboardmanage" 
              element={user ? <ConfigureDash /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/sitemanage" 
              element={user ? <SiteManage /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/dataexplore" 
              element={user ? <DataExplore /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/dataexplorepage" 
              element={user ? <DataExplore_page /> : <Navigate to="/login" />} 
            />
            <Route 
              path="/dashpage" 
              element={user ? <DashPage /> : <Navigate to="/" />} 
            />    
            <Route 
              path="/usermanage" 
              element={user ? <UserManage /> : <Navigate to="/login" />} 
            />                     
            <Route 
              path="/login" 
              element={!user ? <Login /> : <Navigate to="/" />} 
            />                       
            <Route 
             path="/" 
             element={user ? <HomePage /> : <Navigate to="/" />} 
            />
          </Routes>
          </div>
        
    </div>
  );
}

export default App;


//helper functions
function addSecondsToIsoTime(isoTime, secondsToAdd) {
  // Parse the input ISO time string
  let originalTime = new Date(isoTime);

  // Add the specified number of seconds
  originalTime.setSeconds(originalTime.getSeconds() + secondsToAdd);

  // Format the updated time as an ISO string and return it
  return originalTime.toISOString();
}