import React, { useEffect,useState } from 'react'

import { useUserContext } from '../../hooks/useUserContext'
import { useStaticDataContext } from '../../hooks/useStaticDataContext';
import { useNavContext } from '../../hooks/useNavContext';


const Control = ()=>{
    const { dispatchNav } = useNavContext()
    useEffect(()=>{
        dispatchNav({type:'NAV_INDEX',payload:'control'})
    },[])

    const {wsClass} = useStaticDataContext()
    const [listOfInputs,setListOfInputs] = useState(null);
    const { activeSiteData } = useUserContext();
    const [buttonState,setButtonState] = useState([])
    useEffect(()=>{handleServerData(activeSiteData?.input)},[activeSiteData])

    function handleServerData(data){
        if(data){
            const holdObjArray = Object.entries(data)
            const numberOfInputs = holdObjArray.length
            let holdListOfInputs = []
            for(let i = 0;i<numberOfInputs;i++){
            holdListOfInputs.push({inputID:data?.[holdObjArray[i][0]]?.id,inputName:data?.[holdObjArray[i][0]].label.label,data:data?.[holdObjArray[i][0]]})
            }
            setListOfInputs(holdListOfInputs)
        }
    }

    function handelButtonClick(data,index){
        switch(data.data.inputType){
            case 'Toggle':{
                handelToggleButton(data,index)
            }break;
        }
    }

    function handelToggleButton(data,index){
        let holdButtonState = [...buttonState]
        if(!buttonState[index])holdButtonState[index] = {state:true,style:{backgroundColor:`${data.data.colour[1]}`}}
        else{
            if(buttonState[index]?.state){
                if(wsClass?.ws){
                    holdButtonState[index] = {state:false,style:{backgroundColor:`${data.data.colour[0]}`}}
                    wsClass?.ws.send(JSON.stringify({msgType:'control',data:{gateway:data.data.gateway,address:data.data.address,value_1:data.data.value[0],value_2:'NaN',type:'B'}}))
                }
            }else{
                if(wsClass?.ws){
                    holdButtonState[index] = {state:true,style:{backgroundColor:`${data.data.colour[1]}`}}
                    wsClass?.ws.send(JSON.stringify({msgType:'control',data:{gateway:data.data.gateway,address:data.data.address,value_1:data.data.value[1],value_2:'NaN',type:'B'}}))
                }
            }
        }
        setButtonState(holdButtonState)
    }

    return(
        <div style={{width:'100%',height:'80vh',display:'flex',alignItems:'center',justifyContent:'center'}}>
            <div style={{display:'flex', justifyContent:'space-between'}}>
                <ul style={{ listStyleType: 'none' }}>
                {listOfInputs ? listOfInputs.map((item, index) => (
                    <li key={index}><button className = 'dashboard-button' style = {{...buttonState?.[index]?.style,background:'#c4cac4'}} onClick={()=>handelButtonClick(item,index)}>{item.inputName}</button></li>
                    )) : <></>}
                </ul>
            </div>
        </div>
    )
}

export default Control