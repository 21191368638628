import { createContext, useReducer } from 'react';

export const NavContext = createContext();

export const navReducer = (state, action) => {
  switch (action.type) {
    case 'NAV_INDEX':
      return{
        ...state,
        navIndex:action.payload,
      };
    case 'SIDEBAR_STATE':
      return{
        ...state,
        sideBarState:action.payload,
      };
    default:
      return state;
  }
};

export const NavContextProvider = ({ children }) => {
  
  const [state, dispatchNav] = useReducer(navReducer, {
    users: null
  });

  return (
    <NavContext.Provider value={{ ...state, dispatchNav }}>
      {children}
    </NavContext.Provider>
  );
};
