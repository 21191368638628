import React, { useState, useEffect } from 'react';
import { useUserContext } from '../../hooks/useUserContext'
import { useAuthContext } from '../../hooks/useAuthContext';
import { useSitesContext } from '../../hooks/useSitesContext';


let defaultSiteID = ''

const DynamicForm = ({userData})=>{
    const { user } = useAuthContext();
    const { dispatchUser, formFlag, fromOpenFlag } = useUserContext();
    const { sites } = useSitesContext()
    
    const [sitesForm,setSitesForm] = useState()
    const [listOfSites,setListOfSites] = useState([])
    const [error, setError] = useState(null)


    useEffect(()=>{
        const fetchSites = async () => {
            const rootUrl = window.location.origin;
            const response = await fetch(`${rootUrl}/api/sites`, {
              headers: {'Authorization': `Bearer ${user.token}`},
            })
            const json = await response.json()
            if (response.ok) {
                setListOfSites(json)
                defaultSiteID = json[0]["siteID"] // setting the first site ID as the default
            }
          }
      
        if(user){
            fetchSites();
        }
    },[])

    const handleSubmit= async ()=>{
        const sitesLen = sitesForm.length
        let holdSiteArray = []
        let holdSiteArray_index = 0
        for(let i =0;i<sitesLen;i++){
            if(sitesForm[i]!==null){
                holdSiteArray[holdSiteArray_index++] = sitesForm[i]
            }
        }
        if(holdSiteArray.length>0){
            const submitData = {userID: userData._id, data: holdSiteArray}
            const rootUrl = window.location.origin; // Get the root URL dynamically
            try {
            
                const response = await fetch(`${rootUrl}/api/user/site`, {
                method: 'POST',
                body: JSON.stringify(submitData),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${user.token}`
                }
                });
            
                if (!response.ok) {
                const errorMessage = `Server error: ${response.status} - ${response.statusText}`;
                console.error(errorMessage);
                setError(errorMessage);
                return;
                }
                // If the response is successful, you can extract and display the response message
                const responseData = await response.json();
                alert(responseData.message)
                closeForm()
            } catch (error) {
                console.error('An error occurred:', error);
                setError('An error occurred while communicating with the server');
            }
        }else{
            alert("Can't submit an empty form")
        }
    }

    const addSite = () => {
        
        setSitesForm(()=>{
            let holdSites = []
            if(sitesForm)holdSites= [...sitesForm]
            holdSites.push({site_id:defaultSiteID})

            return holdSites
        })
    }

    const handelOnChangeEvent =(e,siteIndex)=>{
        let holdSitesArray = [...sitesForm]
        holdSitesArray[siteIndex]["site_id"] = e.target.value
    }

    const removeSite = (siteIndex)=>{
        let holdSites = [...sitesForm]
        holdSites[siteIndex]=null
        setSitesForm(holdSites)
    }
    const closeForm = () => {
    
        dispatchUser({ type: 'SET_FORM_FLAG', payload: null });
        dispatchUser({ type: 'SET_FORMOPEN_FLAG', payload: null });
    };

const getDataFromServer = async ()=>{

    const rootUrl = window.location.origin; 
    try {
      
      const response = await fetch(`${rootUrl}/api/user/site/${userData._id}`, {
        method: 'GET',       
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      });
  
      if (!response.ok) {
        const errorMessage = `Server error: ${response.status} - ${response.statusText}`;
        console.error(errorMessage);
        setError(errorMessage);
        return;
      }        
      return await response.json();
    } catch (error) {
      console.error('An error occurred:', error);
      setError('An error occurred while communicating with the server');
    }
  };
  const fetchListOfSites = async ()=>{
    
    if(formFlag&&!fromOpenFlag){
        dispatchUser({ type: 'SET_FORMOPEN_FLAG', payload: true });
        const siteList = await getDataFromServer()
        const holdTheSiteList = siteList?.map(item =>({site_id:item.siteID}))
        setSitesForm(holdTheSiteList)
    }
  }
    
  fetchListOfSites();
    return (
        <>
        <div className="form-popup-bg is-visible">      
        <div className="form-container">
        <h1>{"Configure user: "+userData.name}</h1>
        <button  className="close-button" onClick={closeForm}>X</button>
            <div id="formScrollContainer">
            <form>
            {sitesForm?sitesForm.map((site, siteIndex) => ((site!==null)?
                (<table key={`siteTable${siteIndex}`} className="parameterTable">
                    <tbody key={`siteTableBody${siteIndex}`}>
                    <tr key={`siteTr${siteIndex}`} className="parameter">
                        <td key={`siteTd${siteIndex}`}>
                            <select value = {sitesForm[siteIndex].site_id} key={`siteSelect${siteIndex}`} onChange={(event)=>handelOnChangeEvent(event,siteIndex)}>
                                {listOfSites.map((siteOption,optionIndex)=>(
                                    <option key={`${optionIndex}${siteIndex}`} value={siteOption.siteID}>{`${siteOption.siteName} (${siteOption.siteID})` }</option>
                                ))}
                            </select>
                        </td>
                        <td key={`siteTd2${siteIndex}`}>
                            <button className = 'defaultButton' type="button" onClick={() => removeSite(siteIndex)}>
                            Remove Site
                            </button>
                        </td> 
                    </tr>
                    </tbody>
                </table>):(
                    null
                )
            )):<></>}
            </form>    
            </div>
                <button className = "formButton" type="button" onClick={addSite}>
                    Add Site
                </button>
                <button className = "formButton" type="button" onClick={handleSubmit}>Save Data</button>
            </div>
        </div>
        </>
    )
}

export default DynamicForm;