import React, { useEffect} from 'react';
import SitePageDeviceList from './SitePageDetails';
import { useNavContext } from '../../hooks/useNavContext';

const DataExplore_page = React.memo(()=>{
    const {dispatchNav} = useNavContext() 
    useEffect(()=>{
        dispatchNav({type:'NAV_INDEX',payload:'dataExp'})
    },[])
    return(<div className="site">
    <SitePageDeviceList/>
</div>)
})
export default DataExplore_page;