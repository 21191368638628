import React, { useState, useEffect} from 'react';
import { useUserContext } from '../../hooks/useUserContext';
import { useAuthContext } from '../../hooks/useAuthContext';
import { useSitesContext } from '../../hooks/useSitesContext';
import { useNavContext } from '../../hooks/useNavContext';
import { ComponentLoading } from '../subcomp/InlineLoading';

const ConfigureDash = () => {
    const { dispatchNav,navIndex } = useNavContext()
    useEffect(()=>{
        dispatchNav({type:'NAV_INDEX',payload:'dashMan'})
    },[])
    
    const { dispatch, gatewayParam } = useSitesContext();
    const { user } = useAuthContext();
    const { activeSiteData } = useUserContext();
    const [error, setError] = useState(null);
    const [drivers,setDrivers] = useState(null);
    const [liStyles, setLiStyles] = useState({});
    //const [lastClickedLi, setLastClickedLi] = useState(null);
    const [listToFetchData,setListToFetchData] = useState(null)
    const [dashBoardNames,setDashBoardNames] = useState({})
    const [updateDashInfo,setUpdateDashInfo] = useState(false)

    const handleDashSettingsFromServer = (_dashData)=>{
      if(_dashData&&_dashData?.gateWays){
        let objectFromServer_dash = {}
        let objectFromServer_select = {}
        let holdColorObject = {}
        try{
          const gatewayCount = _dashData?.gateWays.length
          for(let i = 0;i<gatewayCount;i++){
            if(_dashData.gateWays[i]){
              if(_dashData[_dashData.gateWays[i]]?.listOfDevices){
                const deviceCount = _dashData[_dashData.gateWays[i]].listOfDevices.length
                for(let j=0;j<deviceCount;j++){
                  const paramCount = _dashData[_dashData.gateWays[i]][_dashData[_dashData.gateWays[i]].listOfDevices[j]].listOfParams.length
                  if(_dashData[_dashData.gateWays[i]]?.listOfDevices[j]){
                    for(let k = 0;k<paramCount;k++){
                      if(_dashData[_dashData.gateWays[i]][_dashData[_dashData.gateWays[i]].listOfDevices[j]]?.listOfParams[k]){
                        const holdValue = _dashData[_dashData.gateWays[i]][_dashData[_dashData.gateWays[i]].listOfDevices[j]][_dashData[_dashData.gateWays[i]][_dashData[_dashData.gateWays[i]].listOfDevices[j]].listOfParams[k]]
                        const holdAbit = `${_dashData.gateWays[i]}.${_dashData[_dashData.gateWays[i]].listOfDevices[j]}.${_dashData[_dashData.gateWays[i]][_dashData[_dashData.gateWays[i]].listOfDevices[j]].listOfParams[k]}.${holdValue.unit}.${holdValue.page}.${holdValue.index}`
                        objectFromServer_dash[holdAbit] = {displayName:holdValue?.dashName,displayColour:holdValue?.displayColour?holdValue.displayColour:'#9aaf9a',bckColour:holdValue?.bckColour?holdValue.bckColour:'#727272'}
                        objectFromServer_select[holdAbit] = true
                        holdColorObject[`${_dashData.gateWays[i]}.${_dashData[_dashData.gateWays[i]].listOfDevices[j]}.${_dashData[_dashData.gateWays[i]][_dashData[_dashData.gateWays[i]].listOfDevices[j]].listOfParams[k]}`] = {color: 'green', cursor: 'pointer', userSelect: 'none', paddingLeft: '1.1rem'}
                      }                 
                    }
                  }
                }
              }
            }
          }
        }catch(error){
          console.error(error)
        }
        setLiStyles(holdColorObject)
        setDashBoardNames(objectFromServer_dash)
        setListToFetchData(objectFromServer_select)
      }
    }

      
    const handelPlotList = (gatewayID,deviceName, parameterName) => {
      setLiStyles((prevStyles) => {
        const currentStyle = prevStyles[`${gatewayID}.${deviceName}.${parameterName}`];
        const newStyle = {};

        if (!currentStyle || currentStyle.color !== 'green') {
          newStyle[`${gatewayID}.${deviceName}.${parameterName}`] = { color: 'green',cursor:'pointer',userSelect: 'none', paddingLeft: '1.1rem' };
        }else{
          newStyle[`${gatewayID}.${deviceName}.${parameterName}`] = { color: 'black',cursor:'pointer',userSelect: 'none', paddingLeft: '1rem'};
        }

        return {
          ...prevStyles,
          ...newStyle,
        };
      });
    }; 
  
    useEffect(()=>{
      if(activeSiteData&&gatewayParam&&(!updateDashInfo)){
        setDrivers(gatewayParam[activeSiteData?.siteID]?.payLoad)
        handleDashSettingsFromServer(gatewayParam[activeSiteData?.siteID]?.dashSettings)
      }
    },[activeSiteData,gatewayParam,])
    
    useEffect(()=>{
      if(updateDashInfo){
        let _holdactivePram = {...gatewayParam}
        _holdactivePram[activeSiteData.siteID].dashSettings = processToSendDashData()
        dispatch({type:'SET_GW_PARAM',payload:_holdactivePram})
        setUpdateDashInfo(false)
      } 
    },[updateDashInfo])
        
    const handelOnclick_li=(event,value,index,indexPage,deviceIndex,paramIndex)=>{
      let thisArray = {...listToFetchData}
      if(thisArray!==undefined)(!thisArray[value])?(thisArray[value]=true):(thisArray[value]=false)
      setListToFetchData(thisArray)
    }
   
    const closeForm = () => {
      dispatch({ type: 'SET_FORM_FLAG', payload: null });
      dispatch({ type: 'SET_FORMOPEN_FLAG', payload: null });
    };
    
    useEffect(() => { // todo add all the function which has to run when the component is closed
      // Your function to run when the component is mounted 
      // Cleanup: Remove the side effect when the component is unmounted
      return () => {
        // the component is unmounted
      };
    }, []);

    const updateInfo=(e,item,type)=>{
      let holdArray = {...dashBoardNames}
      if(type==='name'){
        if(holdArray[item]===undefined){holdArray[item]={}}
        holdArray[item]['displayName'] = e.target.value
        setDashBoardNames(holdArray)
      }else if(type==='colour'){
        if(holdArray[item]===undefined){holdArray[item]={}}
        holdArray[item]['displayColour' ]= e.target.value
        setDashBoardNames(holdArray)
      }else if(type==='bckColour'){
        if(holdArray[item]===undefined){holdArray[item]={}}
        holdArray[item]['bckColour' ]= e.target.value
        setDashBoardNames(holdArray)
      }
    }

    const processToSendDashData = () =>{
      const listOfDashConfig = Object.entries(dashBoardNames)
      const listCount = listOfDashConfig.length
      let objectToSend = {}
      let listOfGateWays = [] // this is so that the backend can use the class without needing to do extra processing
      let listOfDevices = {}
      for(let i = 0;i<listCount;i++){
        const infoArray =  listOfDashConfig[i][0].split('.')
        
        if(listToFetchData[listOfDashConfig[i][0]]){ // only when parameter is active
          if(objectToSend[infoArray[0]]===undefined){objectToSend[infoArray[0]]={};listOfGateWays.push(infoArray[0]);objectToSend[infoArray[0]].listOfDevices = []}
          if(objectToSend[infoArray[0]][infoArray[1]]===undefined){objectToSend[infoArray[0]][infoArray[1]]={};objectToSend[infoArray[0]].listOfDevices.push(infoArray[1]);objectToSend[infoArray[0]][infoArray[1]].listOfParams = []}
          if(objectToSend[infoArray[0]][infoArray[1]][infoArray[2]]===undefined){objectToSend[infoArray[0]][infoArray[1]][infoArray[2]]={};objectToSend[infoArray[0]][infoArray[1]].listOfParams.push(infoArray[2]);}
          objectToSend[infoArray[0]][infoArray[1]][infoArray[2]].unit = infoArray[3]
          objectToSend[infoArray[0]][infoArray[1]][infoArray[2]].page = parseInt(infoArray[4],10)
          objectToSend[infoArray[0]][infoArray[1]][infoArray[2]].index = parseInt(infoArray[5],10)
          objectToSend[infoArray[0]][infoArray[1]][infoArray[2]].dashName = listOfDashConfig[i][1].displayName
          objectToSend[infoArray[0]][infoArray[1]][infoArray[2]].displayColour = listOfDashConfig[i][1].displayColour
          objectToSend[infoArray[0]][infoArray[1]][infoArray[2]].bckColour = listOfDashConfig[i][1]?.bckColour
        }
      }
      objectToSend.gateWays = listOfGateWays
      return objectToSend
    }

    const sendDashDataToServer = async ()=>{
      const payLoad =  processToSendDashData()
      const rootUrl = window.location.origin; 
      const response = await fetch(`${rootUrl}/api/sites/dashData`, {
        method: 'POST',
        body: JSON.stringify({data:payLoad,site:activeSiteData.siteID}),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      })
      const json = await response.json()
      
      if (!response.ok) {
        setError(json.error)
        
      }
      if (response.ok) {
        //dispatch({type:'SET_GW_PARAM',payload:})
        setUpdateDashInfo(true)
      }
    }

    return (
    <div className="site"> 
      <div className='sitePage-flex'>    
        <div className='sitePage-container'>
          {(drivers !== null && activeSiteData) ? (
            activeSiteData.gateways.map((gateway, index) => (
              <div key={gateway?.gatewayID}>
                <h1>{gateway?.gatewayID}</h1>
                {drivers[gateway?.gatewayID].map((page, indexPage) => (
                  <ul key={`${indexPage}_${gateway?.gatewayID}`}>
                    {page !== null ? (
                      page.map((device, deviceIndex) => (
                        <ul key={`${deviceIndex}_${device?.deviceName}_${gateway?.gatewayID}`}>
                          {device.deviceName}
                          <>
                            {device?.parameters.map((param, paramIndex) => (
                              <li
                                key={`${deviceIndex}_${device?.deviceName}_${gateway?.gatewayID}_${paramIndex}`}
                                value = {`${gateway?.gatewayID}.${device?.deviceName}.${param?.parameterName}.${param?.unit}.${indexPage}.${param?.deviceValue}`}
                                style={(liStyles[`${gateway?.gatewayID}.${device?.deviceName}.${param?.parameterName}`]||{cursor:'pointer',userSelect: 'none',paddingLeft: '1rem'})}
                                onClick={(event) => {
                                  handelPlotList(gateway?.gatewayID,device?.deviceName, param?.parameterName);
                                  /*setLastClickedLi(`${gateway.gatewayID}.${device.deviceName}.${param.parameterName}`);*/
                                  handelOnclick_li(event,`${gateway?.gatewayID}.${device?.deviceName}.${param?.parameterName}.${param?.unit}.${indexPage}.${param?.deviceValue}`,index,indexPage,deviceIndex,paramIndex);
                                }}
                              >
                                {param.parameterName}
                              </li>
                            ))}
                          </>
                        </ul>
                      ))
                    ) : null}
                  </ul>
                ))}
              </div>
            ))
          ) : (
            <ComponentLoading/>
          )}
        </div>
        <div className='sitePage-selectedContainer'>
          <div>
            <h1>Dashboard Parameters</h1>
          </div>
          <div className='sitePage-timeContainer'>
            {(activeSiteData!==null&&activeSiteData&&activeSiteData!==undefined)?activeSiteData?.gateways.map((gateway,gatewayIndex)=>(
              <div key={`gatewayDiv${gatewayIndex}`}>
                <h2 key = {`gatewayH2${gatewayIndex}`}>{gateway.gatewayID}</h2>
                
                  <ul key={`gatewayUl${gatewayIndex}`} style={{ listStyle: 'none', padding: 0, display: 'flex', flexDirection: 'column' }}>
                    {(listToFetchData !== null && listToFetchData !== undefined) ? Object.entries(listToFetchData).map((item,index) => (
                      (item[1] && (gateway.gatewayID === item[0].split('.')[0])) ? (
                        <li key={`gatewayLi${gatewayIndex}${index}`} style={{ display: 'flex', alignItems: 'center', marginBottom: '8px' }}>
                          <span key = {`gatewaySpan${gatewayIndex}${index}`}>{(item[1]) ? item[0].replace(`${gateway.gatewayID}.`, '').replace(`.${item[0].split('.')[3]}.${item[0].split('.')[4]}.${item[0].split('.')[5]}`, '') : null}</span>
                          <input key = {`gatewayInput${gatewayIndex}${index}`} type='text'  value={dashBoardNames[item[0]]?dashBoardNames[item[0]]?.displayName:'addName'} onChange={(e) => updateInfo(e, item[0],'name')} style={{ marginLeft: '8px', color: dashBoardNames[item[0]]?'black':'grey',fontStyle:dashBoardNames[item[0]]?'':'italic'}} />
                          <input type="color" value={dashBoardNames[item[0]]?.displayColour} onChange={(e) => updateInfo(e, item[0],'colour')}/>
                          <input type="color" value={dashBoardNames[item[0]]?.bckColour} onChange={(e) => updateInfo(e, item[0],'bckColour')}/>
                        </li>
                      ) : null
                    )) : null}
                  </ul>
              </div>
            )):<></>}
          </div>
          
          <button className = 'defaultButton' onClick={()=>sendDashDataToServer()}>Update Dasboard</button>
          </div>
      </div>
      
    </div>
  );
};

export default ConfigureDash;