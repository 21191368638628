import React ,{useRef,useEffect}from "react";
import LiveDisplay from "./LiveDisplay";
import { useSemiLiveDataContext } from "../../../hooks/useSemiLiveDataContext";
import { unitConverter } from "../../../components/functionalComponents/unitConverter";
import { toSigFigs } from "../../../components/functionalComponents/sigFig";
const echarts = require('echarts')
const myImage = require('../../../images/Energyneering_Clean.png')


const JustTheChart = React.memo(({argument}) => {

  const {liveChart} = useSemiLiveDataContext();

  const unit = liveChart?.[argument?.siteID]?.[argument?.chartSelect?.split('.')[0]]?.displayInfo?.[argument?.chartSelect]?.dashUnit
  let holdData = {...argument.data}
  holdData.areaStyle = {
    opacity: 1,
    color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
      {
        offset: 0,
        color: argument?.addSet?.colour?`${argument?.addSet?.colour}FF`:'#FF7B00FF'
      },
      {
        offset: 1,
        color: argument?.addSet?.colour?`${argument?.addSet?.colour}22`:'#FF7B0022'
      }
    ])
  }
  holdData.connectNulls = true;
  holdData.lineStyle= {
    width: 2,
    color: argument?.addSet?.colour?`${argument?.addSet?.colour}FF`:'#FF7B00FF'
  }
    const chartRef = useRef(null)
    const options = { // #9aaf9a
        grid:{
          left:argument.clean?0:6,
          bottom:0,
          top:30,
          right: 0,
          //top:argument.clean?30:15,
          containLabel: argument.clean?false:true
        },
        legend:{
          left: 10,
          top: '20rem',
          type: 'scroll',
          orient: 'horizontal',
          show:argument.clean?false:false
        },
        animation: false,
        xAxis: {
          type: 'time',
          axisLine:{
            show:false
          },
          axisTick:{
            show:false
          },
          axisLabel:{
            margin:argument.clean?-10:-25,
            fontSize:'1.5rem',
            color:'white',
            formatter: (value, index) => {
              const holdDate = new Date(value).toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: false });

              return ((holdDate==='24:00'||holdDate==='00:00'))?'':holdDate
            },
          },
          show:argument.clean?true:true,
          
        },
        yAxis: {
          type: 'value',
          boundaryGap: [0, '100%'],
          show:argument.clean?false:true,
          splitLine:{show:false},
          axisLabel:{
            margin: -100,
            fontSize:'1.5rem',
            color:'white',
            left:0,
            formatter:(value,index)=>{
              const holdValue = unitConverter(value,unit)
              const intHold = holdValue?.value.toFixed()
              if(holdValue?.value%intHold)return ''
              return index===0?"":`${holdValue.value}${holdValue.unit}`
            }
          },
          max:'dataMax'
        },
        series: holdData,
        connectNulls: false,
      };
    useEffect(()=>{
        const Chart = echarts.init(chartRef.current)
        Chart.setOption(options)
        return()=>{
            Chart.dispose();
        }
    },[argument])
    
    return(
        <div ref = {chartRef} style={{ width: '100%',height:'100%' }}/>
    )
})

const DisplayChart = React.memo(({argument}) => {
    return(
        <div style={{position:'relative',width: '100%',height:'100%',zIndex:2}}>
            <JustTheChart argument = {argument}/>
        </div>
    )
})

export default DisplayChart

