import React, { useState, useEffect } from 'react';
import { useGatewaysContext } from '../../hooks/useGatewaysContext';
import { useAuthContext } from '../../hooks/useAuthContext';
import { registerPostUpdate } from 'echarts';

const DynamicForm = ({ gateway}) => {
  const { dispatch, formFlag, fromOpenFlag } = useGatewaysContext();
  const { user } = useAuthContext()
  const [error, setError] = useState(null)
  
  const [driverDataSend, setDriverDataSend] = useState(null);
  const defaultObject = [
    {deviceName: '',
      parameters: [
        {
          parameterName: '',
          deviceValue: 0,
          unit: '',
          description: '',
          authLevel: '',
        },
      ],
    },
  ];
  const [devices, setDevices] = useState(defaultObject);
  const [enableGW,setEnableGW] = useState(false);
  const [pages,setPage] = useState(1)
  const addDevice = () => {
    setDevices((prevDevices) => [
      ...prevDevices,
      {
        deviceName: '',
        parameters: [
          {
            parameterName: '',
            parameterValue: '',
            unit: '',
            description: '',
            authLevel: '',
          },
        ],
      },
    ]);
  };

  const deleteDevice = (index) => {
    setDevices((prevDevices) => {
      const updatedDevices = [...prevDevices];
      updatedDevices.splice(index, 1);
      return updatedDevices;
    });
  };

  const addParameter = (deviceIndex) => {
    setDevices((prevDevices) => {
      return prevDevices.map((device, index) => {
        if (index === deviceIndex) {
          return {
            ...device,
            parameters: [
              ...device.parameters,
              {
                deviceValue: '',
                unit: '',
                description: '',
                authLevel: '',
              },
            ],
          };
        }
        return device;
      });
    });
  };

  const deleteParameter = (deviceIndex, parameterIndex) => {
    setDevices((prevDevices) => {
      return prevDevices.map((device, index) => {
        if (index === deviceIndex) {
          return {
            ...device,
            parameters: device.parameters.filter(
              (param, paramIndex) => paramIndex !== parameterIndex
            ),
          };
        }
        return device;
      });
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  
    if (!user) {
      setError('You must be logged in')
      return
    }
  
    const rootUrl = window.location.origin; // Get the root URL dynamically
    const gatewayPayload = {
      gatewayID: gateway?.gatewayID,
      payLoad: devices,
      page: pages
    }
  
    try {
      const response = await fetch(`${rootUrl}/api/gateways/driver`, {
        method: 'POST',
        body: JSON.stringify(gatewayPayload),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      });
  
      if (!response.ok) {
        const errorMessage = `Server error: ${response.status} - ${response.statusText}`;
        console.error(errorMessage);
        setError(errorMessage);
        return;
      }
      // If the response is successful, you can extract and display the response message
      const responseData = await response.json();
      alert(responseData?.message)
      closeForm()
    } catch (error) {
      console.error('An error occurred:', error);
      setError('An error occurred while communicating with the server');
    }
  };
  

  const closeForm = () => {
    dispatch({ type: 'SET_FORM_FLAG', payload: null });
    dispatch({ type: 'SET_FORMOPEN_FLAG', payload: null });
  };

  const getDataFromServer = async (page)=>{

    const rootUrl = window.location.origin; 
    try {
      const fetchDataJSON = {gateway_id: gateway?._id, page:page}
      const response = await fetch(`${rootUrl}/api/sites/driver/${JSON.stringify(fetchDataJSON)}`, {
        method: 'GET',       
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      });
  
      if (!response.ok) {
        const errorMessage = `Server error: ${response.status} - ${response.statusText}`;
        console.error(errorMessage);
        setError(errorMessage);
        return;
      }
        
      return await response.json();
    } catch (error) {
      console.error('An error occurred:', error);
      setError('An error occurred while communicating with the server');
    }
  };

  
  const displaySavedData = async (page) => {
    try {
      // Fetch data from the server
      const response = await getDataFromServer(page);
      if ((()=>{if(typeof response?.driverArray !== 'undefined'){if(response?.driverArray !== null){if(typeof response?.driverArray[0] !== undefined){return true}else{return false}}else return false}else return false})()){        
        setDriverDataSend(response.driverArray);
        setDevices(response.driverArray);
        setEnableGW(response.enable)
      }else{
        setDriverDataSend(defaultObject);
        setDevices(defaultObject);
      }      
    } catch (error) {
      
      console.error("An error occurred while fetching data:", error);
      setError("An error occurred while fetching data from the server");
    }
  };
  if(formFlag&&!fromOpenFlag){
    dispatch({ type: 'SET_FORMOPEN_FLAG', payload: true });
    displaySavedData(1)
  }

  const pageChange = async (e)=>{
    const newValue = parseInt(e.target.value);
    if (!isNaN(newValue) && newValue >= 1 && newValue <= 16) {
      setPage(newValue);
      displaySavedData(newValue)
    }
  }

  async function toggleGateway(flag){
    const rootUrl = window.location.origin; // Get the root URL dynamically
    const gatewayPayload = {
      gatewayID: gateway?.gatewayID,
      payLoad: flag
    }
  
    try {
      const response = await fetch(`${rootUrl}/api/gateways/enablelog`, {
        method: 'POST',
        body: JSON.stringify(gatewayPayload),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${user.token}`
        }
      });
  
      if (!response.ok) {
        const errorMessage = `Server error: ${response.status} - ${response.statusText}`;
        console.error(errorMessage);
        return;
      }
      // If the response is successful, you can extract and display the response message
      setEnableGW(flag)
      const responseData = await response.json();
    } catch (error) {
      console.error('An error occurred:', error);
    }
    
  }

  async function downloadDriver(e){
    e.preventDefault();
    
    if (!user) {
      setError('You must be logged in')
      return
    }
    
    const gatewayPayload = {
      payLoad: devices,
      page: pages
    }
    downloadJSON(JSON.stringify(gatewayPayload),`driver_${pages} [${gateway?.gatewayID}]`)
  }

  function downloadJSON(jsonData, filename) {
    const blob = new Blob([jsonData], { type: 'application/json' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = filename;
    link.click();
    link.remove();
    window.URL.revokeObjectURL(link.href);
  }

  class FileUploader extends React.Component {
    handleFileUpload = event => {
      const file = event.target.files[0]; // Get the uploaded file
      const reader = new FileReader(); // Create a FileReader object
  
      reader.onload = event => {
        const jsonData = event.target.result; // Get the file content (JSON data)
        const jsonObject = JSON.parse(jsonData); // Parse JSON data into a JavaScript object
        if(jsonObject?.payLoad)setDevices(jsonObject?.payLoad)
      };
  
      reader.readAsText(file); // Read the uploaded file as text
    };
  
    render() {
      return (
        <div>
          <input type="file" onChange={this.handleFileUpload} accept=".json" />
        </div>
      );
    }
  }


  return (
    <div className="form-popup-bg is-visible">      
    <div className="form-container">
    <button className="close-button" onClick={closeForm}>X</button>
    <input type = "number" id="pageInput" defaultValue={1} min={1} max={16} onChange={pageChange}></input>
    <h1>{"Configure: "+gateway.gatewayID}</h1>
    <input checked = {enableGW} onClick={(e)=>toggleGateway(e.target.checked)} type='checkbox'></input>
    <div id="formScrollContainer">      
      <form onSubmit={handleSubmit}>
        {(devices!==null)&&devices.map((device, deviceIndex) => (
          <div key={deviceIndex} className="device">
            <h3 htmlFor="deviceName">Device Name:</h3>
            <input
              autoComplete="off"
              type="text"
              name="deviceName"
              value={device.deviceName}
              onChange={(e) => {
                const value = e.target.value;
                setDevices((prevDevices) => {
                  const updatedDevices = [...prevDevices];
                  updatedDevices[deviceIndex].deviceName = value;
                  return updatedDevices;
                });
              }}
            />
            <button className = 'defaultButton' type="button" onClick={() => deleteDevice(deviceIndex)}>
              Delete Device
            </button>
            <div className="parametersContainer">
              <table className="parameterTable">
                <thead>
                  <tr>
                    <th>Lable</th>
                    <th>Index</th>
                    <th>Unit Of Measure</th>
                    <th>Description</th>
                    <th>Authorization Level</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {device.parameters.map((parameter, parameterIndex) => (
                    <tr key={parameterIndex} className="parameter">
                      <td>
                        <input
                          autoComplete="off"
                          type="text"
                          name="paramName"
                          value={parameter?.parameterName}
                          onChange={(e) => {
                            const value = e.target.value;
                            setDevices((prevDevices) => {
                              const updatedDevices = [...prevDevices];
                              updatedDevices[deviceIndex].parameters[
                                parameterIndex
                              ].parameterName = value;
                              return updatedDevices;
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          autoComplete="off"
                          type="number"
                          name="deviceValue"
                          value={parameter?.deviceValue}
                          onChange={(e) => {
                            const value = parseInt(e.target.value);
                            setDevices((prevDevices) => {
                              const updatedDevices = [...prevDevices];
                              updatedDevices[deviceIndex].parameters[
                                parameterIndex
                              ].deviceValue = value;
                              return updatedDevices;
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          autoComplete="off"
                          type="text"
                          name="unit"
                          value={parameter?.unit}
                          onChange={(e) => {
                            const value = e.target.value;
                            setDevices((prevDevices) => {
                              const updatedDevices = [...prevDevices];
                              updatedDevices[deviceIndex].parameters[
                                parameterIndex
                              ].unit = value;
                              return updatedDevices;
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          autoComplete="off"
                          type="text"
                          name="description"
                          value={parameter?.description}
                          onChange={(e) => {
                            const value = e.target.value;
                            setDevices((prevDevices) => {
                              const updatedDevices = [...prevDevices];
                              updatedDevices[deviceIndex].parameters[
                                parameterIndex
                              ].description = value;
                              return updatedDevices;
                            });
                          }}
                        />
                      </td>
                      <td>
                        <input
                          autoComplete="off"
                          type="text"
                          name="authLevel"
                          value={parameter?.authLevel}
                          onChange={(e) => {
                            const value = e.target.value;
                            setDevices((prevDevices) => {
                              const updatedDevices = [...prevDevices];
                              updatedDevices[deviceIndex].parameters[
                                parameterIndex
                              ].authLevel = value;
                              return updatedDevices;
                            });
                          }}
                        />
                      </td>
                      <td>
                        <button className = 'defaultButton'
                          type="button"
                          onClick={() =>
                            deleteParameter(deviceIndex, parameterIndex)
                          }
                        >
                          Delete Parameter
                        </button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <button
                type="button"
                className="addParameter"
                onClick={() => addParameter(deviceIndex)}
              >
                Add Parameter
              </button>
            </div>
          </div>
        ))}        
      </form>      
      </div>
      <button className = "formButton" type="button" onClick={addDevice}>
          Add Device
        </button>
        <button  className = "formButton" type="button" onClick={handleSubmit}>Save Data</button>
        <button  className = "formButton" type="button" onClick={downloadDriver}>Download Driver</button>
        <FileUploader/>
      <pre id="jsonOutput"></pre>
    </div>    
    </div>
  );
};

export default DynamicForm;
