import { useState } from 'react';
import { useUserContext } from '../../hooks/useUserContext';
import { useAuthContext } from '../../hooks/useAuthContext';
import jwtDecode from 'jwt-decode';
import DynamicForm from "./UserMangeForm"

// date fns
import formatDistanceToNow from 'date-fns/formatDistanceToNow';

const UserDetails = ({ users }) => {
  const { dispatchUser, formFlag, fromOpenFlag } = useUserContext();
  const { user } = useAuthContext();
  const payload = jwtDecode(user.token)
  
  const [isEditing, setIsEditing] = useState(false);
  const [editUserName, setEditUserName] = useState(users.name); 
  const [editRole, setEditUserRole] = useState(users.role); 
  const [editEmial, setEditEmail] = useState(users.email);
  const [showPrompt, setShowPrompt] = useState(false); // Add state for prompt visibility

  const [isFormOpen, setIsFormOpen] = useState(false); // New state for form visibility

  const rootUrl = window.location.origin; // Get the root URL dynamically
  const deleteUser = async () => {
    if (!user||payload.role!=='admin') {
      return;
    }

    const response = await fetch(`${rootUrl}/api/user/` + users._id, {
      method: 'DELETE',
      headers: {
        'Authorization': `Bearer ${user.token}`
      }
    });
    const json = await response.json();

    if (response.ok) {
      dispatchUser({ type: 'DELETE_USER', payload: json });
    }
  };

  const handleEditClick = () => {
    setIsEditing(true); // Enable editing mode
  };

  const handleDeleteClick = () => {
    setShowPrompt(true); // Show the prompt
  };

  const handleConfirmDelete = () => {
    setShowPrompt(false); // Hide the prompt
    deleteUser(); // Proceed with users deletion
  };

  const handleCancelDelete = () => {
    setShowPrompt(false); // Hide the prompt
  };

  const handleCancelEdit = () => {
    setIsEditing(false); // Exit editing mode
    // Reset the edited values to their original values
    setEditUserName(users.name);
    setEditUserRole(users.role);
  };

  const handleConfirmEdit = () => {
    // Perform update operation with the edited values
    const updatedUser = {
      ...users,
      name: editUserName,
      role: editRole,
      email: editEmial
    };

    // Make a PUT or PATCH request to the API endpoint to update the site
    // Update the request URL and payload structure as per your backend implementation
    fetch(`${rootUrl}/api/user/${users._id}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
        'Authorization': `Bearer ${user.token}`
      },
      body: JSON.stringify(updatedUser)
    })
      .then(response => response.json())
      .then(json => {
        // Dispatch action to update the site in the context
        dispatchUser({ type: 'UPDATE_USER', payload: json });
        setIsEditing(false); // Exit editing mode
      })
      .catch(error => {
        // Handle error if necessary
        console.error(error);
      });
  };

  const openForm = () => {
    dispatchUser({ type: 'SET_FORM_FLAG', payload: users.name });
    setIsFormOpen(true);
  };

  if(isFormOpen){
    setIsFormOpen(false)
  }

  const handleSettingsClick = (event) => {
    event.preventDefault();
    openForm();
  }

  return (
    <>
      {(formFlag===users.name)  &&<DynamicForm userData={users}></DynamicForm>}
      <div className="manage-details">
        <h4>{isEditing?(<input type = "text" value = {editUserName} onChange={(e)=>{setEditUserName(e.target.value)}}></input>):(users.name)}</h4>
        <p><strong>User role: </strong>{isEditing?(<input type = "text" value = {editRole} onChange={(e)=>{setEditUserRole(e.target.value)}}></input>):(users.role)}</p>
        <p><strong>User email: </strong>{isEditing?(<input type="text" value ={editEmial} onChange={(e)=>{setEditEmail(e.target.value)}}></input>):(users.email)}</p>
        <div>
        {user && payload.role === 'admin' && ( // Add condition to check if user is admin
          <>
          {isEditing?(
            <>
              <button className = 'defaultButton' onClick={handleConfirmEdit}>Save</button>
              <button className = 'defaultButton' onClick={handleCancelEdit}>Cancel</button>
            </>
          ):(
             <>
              <span className="material-symbols-outlined" onClick={handleSettingsClick}>settings</span>
              <span className="material-symbols-outlined" onClick={handleDeleteClick}>delete</span>
              <span className="material-symbols-outlined" onClick={handleEditClick}>edit</span>
              </>  
            )           
          }
            
          </>
        )}
        </div>

        {showPrompt && (
          <div className="prompt">
            <p>Are you sure you want to delete this users?</p>
            <button className = 'defaultButton' onClick={handleConfirmDelete}>Yes</button>
            <button className = 'defaultButton' onClick={handleCancelDelete}>No</button>
          </div>
        )}
      </div>
    </>
  );
};

export default UserDetails;
